import { Action, ActionTypes } from "../../actions";

// Model
import { Activity } from "../../../models/Activity.model";
import { Folder } from "../../../models/folder.model";

export type ActivityGroup = {
  [key: number]: Activity[];
};

export type FolderGroup = {
  [key: number]: Folder | null;
};

export const newsActivityListReducer = (
  state: ActivityGroup = {},
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.fetchNewsActivityList:
      const key = action.idCategory;
      state = { ...state };
      state[key] = action.payload;
      return state;
    default:
      return state;
  }
};

export const newsFolderReducer = (state: FolderGroup = {}, action: Action) => {
  switch (action.type) {
    case ActionTypes.fetchNewsFolder:
      const key = action.idCategory;
      state = { ...state };
      state[key] = action.payload;
      return state;
    default:
      return state;
  }
};
