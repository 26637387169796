// React
import React from "react";

// Material
import { Box } from "@material-ui/core";

// Models
import { Activity } from "../../models/Activity.model";

// Components
import CardActivity from "../CardActivity/CardActivity";

/**
 * ActivityListProps props
 */
interface ActivityListProps {
  list: Activity[];
  locked?: boolean;
  onLockedClick?: () => void;
}

/**
 * ActivityList component
 */
const ActivityList = ({ list, locked, onLockedClick }: ActivityListProps) => {
  return (
    <div id="activityList">
      {list && list.length > 0
        ? list.map((activity: Activity) => (
            <Box key={activity.id} id={`${activity.id}`} marginTop={1}>
              <CardActivity
                locked={locked}
                onLockedClick={onLockedClick}
                activity={activity}
              />
            </Box>
          ))
        : null}
    </div>
  );
};

export default React.memo(ActivityList);
