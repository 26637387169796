import { Action, ActionTypes } from "../../actions";

// Model
import { User } from "../../../models/user.model";

export const userReducer = (state: User | null = null, action: Action) => {
  switch (action.type) {
    case ActionTypes.fetchUser:
      return action.payload;
    default:
      return state;
  }
};
