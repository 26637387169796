// Models
import { Category } from "../models/category.model";
import { Activity } from "../models/Activity.model";

export const getGlobalActivityTime = (
  contentList: Array<Category | Activity>
): number | 0 => {
  return contentList.reduce((a, b) => a + b.time.estimated, 0);
};

/** Calculate confirmed activity time */
export const getConfirmedActivityTime = (
  contentList: Array<Category | Activity>
): number | 0 => {
  return contentList.reduce((a, b) => a + b.time.confirmed, 0);
};
