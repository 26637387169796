import React from "react";
// Lib
import { AutoSizer } from "react-virtualized";
// Material
import {
  AppBar,
  CssBaseline,
  Grid,
  Toolbar,
  Typography
} from "@material-ui/core";
// Style
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  MuiThemeProvider,
  useTheme
} from "@material-ui/core/styles";
// Images
import logo from "../../assets/RF_Elearning_logo.png";
import iconProblem from "../../assets/icon-problem.svg";

const drawerWidth = 300;

const themeRF = createMuiTheme({
  palette: {
    primary: {
      main: "#77C35E"
    }
  },
  overrides: {
    MuiToolbar: {
      regular: {
        height: "56px",
        minHeight: "56px"
      }
    }
  }
});

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end"
    },
    logoContainer: {
      flexGrow: 1,
      [theme.breakpoints.down("xs")]: {
        textAlign: "center"
      }
    },
    appBar: {
      zIndex: 2000,
      backgroundColor: "#ffffff",
      marginLeft: drawerWidth,
      width: `100%`,
      justifyContent: "center"
    },
    logo: {
      width: 200,
      [theme.breakpoints.down("xs")]: {
        width: "180px"
      }
    },
    viewContainer: {
      flexGrow: 1,
      padding: theme.spacing(0)
    },
    content: {
      margin: "0 auto",
      paddingBottom: 20,
      minWidth: "90%",
      height: "calc(100vh  - 105px)"
    },
    iconProblem: {
      marginBottom: theme.spacing(5),
      width: 223,
      height: 60,
      objectFit: "contain"
    },
    title: {
      color: "#000000",
      textAlign: "center",
      fontWeight: 600,
      lineHeight: 1.54
    },
    subTitle: {
      color: "#818797",
      textAlign: "center",
      fontWeight: 300,
      lineHeight: 1.3
    },
    button: {
      color: "#FFF",
      fontWeight: 600
    }
  })
);

interface IServiceUnvailable {
  statusCode?: number;
}

/**
 * ServiceUnvailable component
 */
const ServiceUnvailable = ({ statusCode }: IServiceUnvailable) => {
  /** Classes */
  const classes = useStyles();
  /** Theme */
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={themeRF}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar} color="default">
          <Toolbar>
            <div className={classes.logoContainer}>
              <img src={logo} className={classes.logo} alt="rf-elearning" />
            </div>
          </Toolbar>
        </AppBar>
        <main className={classes.viewContainer}>
          <div className={classes.drawerHeader} />
          <div className={classes.content}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <Grid
                    container={true}
                    style={{ width, height, padding: theme.spacing(3) }}
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >
                    <img
                      className={classes.iconProblem}
                      src={iconProblem}
                      alt="Erreur"
                    />
                    {(() => {
                      switch (statusCode) {
                        case 404:
                          return (
                            <>
                              <Typography
                                variant="h5"
                                gutterBottom={true}
                                className={classes.title}
                              >
                                Cette page n'existe pas.
                              </Typography>
                            </>
                          );
                        case 401:
                          return (
                            <>
                              <Typography
                                variant="h5"
                                gutterBottom={true}
                                className={classes.title}
                              >
                                Vous n'êtes pas autorisé à y accéder.
                              </Typography>
                            </>
                          );
                        default:
                          return (
                            <>
                              <Typography
                                variant="h5"
                                gutterBottom={true}
                                className={classes.title}
                              >
                                Il semble qu’il y ait un petit soucis
                                <br />
                                lors du chargement du site
                              </Typography>
                              <Typography
                                variant="h6"
                                gutterBottom={true}
                                className={classes.subTitle}
                              >
                                Nous nous excusons de ce désagrément et vous
                                invitons à revenir un peu plus tard.
                              </Typography>
                            </>
                          );
                      }
                    })()}
                  </Grid>
                );
              }}
            </AutoSizer>
          </div>
        </main>
      </MuiThemeProvider>
    </div>
  );
};

export default ServiceUnvailable;
