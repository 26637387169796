// React
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

// Store
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../store/reducers";
import { fetchNewsActivityList, fetchNewsFolder } from "../../store/actions";
import { setProcessingTime } from "../../store/actions/modules/time";

// Material
import { Box, makeStyles, createStyles } from "@material-ui/core";

// Components
import ActivityList from "../../features/ActivityList/ActivityList";
import CardFolder from "../../features/CardFolder/CardFolder";
import NoResult from "../../features/NoResult/NoResult";
import CardActivity from "../../features/CardActivity/CardActivity";
import StarterQuizCard from "../Quiz/QuizStarterCard";
import LockedBanner from "../../features/LockedBanner/LockedBanner";

// Models
import { Activity, QuizStarter } from "../../models/Activity.model";
import { Folder } from "../../models/folder.model";

// Services
import { getBreadCrumb } from "../../services/navigation.service";
import { getQuizStarter } from "../../services/quiz.service";

// Mocks
import { provideFullMock } from "../../testing/model.mock";

// Providers
import { useActivityState } from "../../shared/Activity.provider";
import { useScrollDispatch } from "../../shared/Scroll.provider";

// Helpers
import { relocateScroll } from "../../helpers/page.helper";

// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minWidth: 320,
      maxWidth: 1000,
      margin: "0 auto",
      marginBottom: 15
    }
  })
);

/**
 * News component
 */
const News = withRouter(({ match }) => {
  const classes = useStyles();

  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();

  /**
   * Fake Folder
   */
  const fakeFolder = provideFullMock().folder;

  /**
   * Fake Activity
   */
  const fakeActivity = provideFullMock().lessonVideo;

  const { id } = match.params;
  /** Activity list state */
  const activityList: Activity[] = useSelector(
    (state: StoreState) => state.newsActivityList[id]
  );
  /** Folder state */
  const folder: Folder | null = useSelector(
    (state: StoreState) => state.newsFolder[id]
  );

  /**
   * Loading state
   */
  const [loading, setLoading] = useState(true);

  /**
   * Loading state folder
   */
  const [loadingFolder, setLoadingFolder] = useState(true);
  /** Domain name */
  const [domainName, setDomainName] = useState<string>("");
  /** Loading domain */
  const [loadingDomain, setLoadingDomain] = useState(true);
  /** activitySelected */
  const { activitySelected } = useActivityState();
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();
  /** Dispatch store */
  const dispatch = useDispatch();
  /** Processing time state */
  const processingTime: boolean = useSelector(
    (state: StoreState) => state.processingTime
  );
  /** Quiz Starter State */
  const [quizStarter, setQuizStarter] = useState<QuizStarter | undefined>();
  /** show locked notification state */
  const [showLockedNotification, setShowLockedNotification] = useState(false);

  const activateLockedNotification = () => {
    setShowLockedNotification(true);
    setTimeout(() => {
      setShowLockedNotification(false);
    }, 7500);
  };

  /**
   * Use effect to relocate scroll
   */
  useEffect(() => {
    // Locate scroll
    setTimeout(() => {
      if (relocateScroll(activitySelected)) {
        dispatchScroll({
          type: "setScrollPosition",
          scrollPosition: Number.parseInt(activitySelected.split(";")[1], 10)
        });
      } else {
        dispatchScroll({
          type: "setScrollPosition",
          scrollPosition: 0
        });
      }
    }, 1000);
  }, [activityList]);

  /**
   * Use effect activity list
   */
  useEffect(() => {
    ((activityList && activityList.length > 0) || activityList === null) &&
      setLoading(false);
  }, [activityList]);

  /**
   * Use effect folder
   */
  useEffect(() => {
    (folder || folder === null) && setLoadingFolder(false);
  }, [folder]);

  /** Get activity list and folder */
  const fetchData = () => {
    const { id } = match.params;
    dispatch(fetchNewsActivityList(fetchWithCredentials, id));
    dispatch(fetchNewsFolder(fetchWithCredentials, id));
  };

  /**
   * Use effect get activity list
   */
  useEffect(() => {
    const { id } = match.params;

    setQuizStarter(getQuizStarter(id));

    activityList === undefined && setLoading(true);
    folder === undefined && setLoadingFolder(true);

    const fetchBreadCrumb = async () => {
      setLoadingDomain(true);
      const result = await getBreadCrumb(fetchWithCredentials, id);
      setDomainName(result.data.domain);
      setLoadingDomain(false);
    };

    let isSubscribed = true;
    if (isSubscribed) {
      fetchData();
      fetchBreadCrumb();
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, [match.params.id]);

  /** Use effect force reload activity subject list if time is processing */
  useEffect(() => {
    if (processingTime) {
      fetchData();
      dispatch(setProcessingTime(false));
    }
  }, [processingTime]);

  return (
    <div id="news">
      {showLockedNotification && (
        <div style={{ marginTop: -20 }}>
          <LockedBanner />
        </div>
      )}
      {loadingDomain ? (
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          <title>{domainName} - Actualités</title>
        </Helmet>
      ) : null}
      {quizStarter && (
        <div className={classes.container}>
          <StarterQuizCard
            quizStarter={quizStarter}
            domainId={match.params.id}
          />
        </div>
      )}
      {loadingFolder ? (
        <CardFolder fake={true} folder={fakeFolder} />
      ) : folder ? (
        <CardFolder
          folder={folder}
          locked={!!quizStarter}
          onLockedClick={activateLockedNotification}
        />
      ) : null}
      {loading ? (
        <Box marginTop={3}>
          <CardActivity fake={true} activity={fakeActivity} />
        </Box>
      ) : activityList && activityList.length > 0 ? (
        <ActivityList
          locked={!!quizStarter}
          onLockedClick={activateLockedNotification}
          list={activityList}
        />
      ) : null}
      {!loading && !loadingFolder && !folder && !activityList ? (
        <NoResult />
      ) : null}
    </div>
  );
});

export default News;
