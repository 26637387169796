import { FetchHomeActivityList, FetchHomeFolder } from "./modules/home";
import { FetchNewsActivityList, FetchNewsFolder } from "./modules/news";
import { FetchUser } from "./modules/user";
import { FetchProcessingTime, FetchStartDate } from "./modules/time";

export enum ActionTypes {
  fetchProcessingTime,
  fetchHomeActivityList,
  fetchHomeFolder,
  fetchNewsActivityList,
  fetchNewsFolder,
  fetchUser,
  fetchStartDate
}

export type Action =
  | FetchProcessingTime
  | FetchHomeActivityList
  | FetchHomeFolder
  | FetchNewsActivityList
  | FetchNewsFolder
  | FetchUser
  | FetchStartDate;
// this along with the enum
// ex.: export type Action = FetchHomeActivityList | AnotherFetchActivity;
