// React
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter, RouteComponentProps } from "react-router";
import ContentLoader from "react-content-loader";

// Material
import {
  makeStyles,
  Container,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Theme,
  Grid,
  createStyles
} from "@material-ui/core";

// Services
import { getUsers } from "../../services/navigation.service";

// Models
import { User } from "../../models/user.model";

// Providers
import { useScrollDispatch } from "../../shared/Scroll.provider";

// Libs
import { useUserAuthentication } from "@dsk-lib/user";

/**
 * Use styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(3),
      width: "100%",
      overflowX: "auto",
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 680
    },
    headerTable: {
      backgroundColor: "#263c46"
    },
    headerTitle: {
      fontSize: 15,
      fontWeight: 600,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: 1,
      color: "#ffffff"
    },
    row: {
      cursor: "pointer",
      borderLeft: "10px solid transparent",
      "&:hover": {
        borderLeft: "10px outset",
        borderLeftColor: "#263c46"
      }
    },
    cell: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 4,
      paddingRight: 4,
      whiteSpace: "nowrap"
    }
  })
);
/**
 * AdminLoader
 */
const AdminLoader = () => (
  <ContentLoader
    height={160}
    width={700}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#e3e3e3"
  >
    <rect x="20" y="10" rx="5" ry="5" width="650" height="20" />
    <rect x="20" y="40" rx="5" ry="5" width="650" height="20" />
    <rect x="20" y="70" rx="5" ry="5" width="650" height="20" />
    <rect x="20" y="100" rx="5" ry="5" width="650" height="20" />
    <rect x="20" y="130" rx="5" ry="5" width="650" height="20" />
  </ContentLoader>
);

/**
 * Admin component
 */
const Admin = (props: RouteComponentProps) => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();
  /** Classes */
  const classes = useStyles();
  /** Loading state */
  const [loading, setLoading] = useState(true);
  /** Users */
  const [users, setUsers] = useState<User[]>([]);
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();

  const { history, match } = props;

  /** Handle dashboard user */
  const handleDashboardUser = (user: User) => {
    history.push({
      pathname: "/mon-suivi/" + user.username,
      state: { user }
    });
  };
  /**
   * Manage scroll
   */
  useEffect(() => {
    dispatchScroll({ type: "setScrollPosition", scrollPosition: 0 });
  }, []);

  /**
   * Use effect get users
   */
  useEffect(() => {
    /** Fetch data */
    const fetchData = async () => {
      setLoading(true);
      const result = await getUsers(fetchWithCredentials);
      setUsers(result.data);
      setLoading(false);
    };
    fetchData();
  }, [match.url]);

  return (
    <Container maxWidth={false}>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Administration</title>
      </Helmet>
      {!loading && users ? (
        <Grid item={true} xs={12}>
          <Paper className={classes.paper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow className={classes.headerTable}>
                  <TableCell
                    className={`${classes.headerTitle} ${classes.cell}`}
                  />
                  <TableCell
                    className={`${classes.headerTitle} ${classes.cell}`}
                    align="left"
                  >
                    Identifiant
                  </TableCell>
                  <TableCell
                    className={`${classes.headerTitle} ${classes.cell}`}
                    align="left"
                  >
                    Nom
                  </TableCell>
                  <TableCell
                    className={`${classes.headerTitle} ${classes.cell}`}
                    align="left"
                  >
                    Prénom
                  </TableCell>
                  <TableCell
                    className={`${classes.headerTitle} ${classes.cell}`}
                    align="left"
                  >
                    Société
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user: User, index: number) => (
                  <TableRow
                    key={index}
                    hover={true}
                    className={classes.row}
                    onClick={() => handleDashboardUser(user)}
                  >
                    <TableCell className={classes.cell}>{index} </TableCell>
                    <TableCell className={classes.cell} align="left">
                      {user.username}
                    </TableCell>
                    <TableCell className={classes.cell} align="left">
                      {user.lastname}
                    </TableCell>
                    <TableCell className={classes.cell} align="left">
                      {user.firstname}
                    </TableCell>
                    <TableCell className={classes.cell} align="left">
                      {user.company}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      ) : (
        <AdminLoader />
      )}
    </Container>
  );
};

export default withRouter(Admin);
