import { action, ActionType } from "typesafe-actions";
import { SET_ERROR } from "./types";

export const setErrorToHandleError = (error: boolean, statusCode?: number) => {
  const arrayError = {
    isError: error,
    statusCode
  };

  return action(SET_ERROR, { ...arrayError });
};

const allActions = {
  setError: setErrorToHandleError
};

export type errorAction = ActionType<typeof allActions>;
