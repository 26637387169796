// React
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";

// Images
import infoIcon from "../../assets/ic-info.svg";

/**
 * Use styles
 */
const useStyles = makeStyles(() => ({
  subtitle: {
    fontStyle: "italic",
    letterSpacing: "normal",
    color: "#818797",
    maxWidth: 620,
    textAlign: "center"
  },
  title: {
    fontWeight: 600,
    letterSpacing: "normal",
    maxWidth: 620,
    textAlign: "center"
  }
}));

/**
 * NoAccess component
 */
const NoAccess: React.FC = () => {
  const classes = useStyles();

  /** Title */
  const title: string = `Vous n'avez pas les droits pour acceder à cette page`;

  /** Subtitle */
  const subtitle: string = `Seuls les administrateurs ont access à cette page`;

  return (
    <Grid
      container={true}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <img src={infoIcon} alt="information icone" />
      <Typography variant="h6" gutterBottom={true} className={classes.title}>
        {title}
      </Typography>
      <Typography
        component="p"
        variant="body1"
        gutterBottom={true}
        paragraph={true}
        className={classes.subtitle}
      >
        {subtitle}
      </Typography>
    </Grid>
  );
};

export default NoAccess;
