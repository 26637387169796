import React, { ReactNode } from "react";
import {
  getItemLocalStorage,
  setItemLocalStorage
} from "../../services/utility.service";

const defaultIdSubjectSelected =
  getItemLocalStorage("setIdSubjectSelected") || "0;0";

export interface Action {
  type: "setIdSubjectSelected";
  idSubjectSelected: string;
}

const SubjectStateContext = React.createContext({
  subjectSelected: defaultIdSubjectSelected
});
const SubjectDispatchContext = React.createContext(
  (() => defaultIdSubjectSelected) as React.Dispatch<Action>
);

function subjectReducer(state: { subjectSelected: string }, action: Action) {
  switch (action.type) {
    case "setIdSubjectSelected": {
      setItemLocalStorage("setIdSubjectSelected", action.idSubjectSelected);
      return { subjectSelected: action.idSubjectSelected };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}${state}`);
    }
  }
}

interface IProps {
  children: ReactNode;
}

function SubjectProvider({ children }: IProps) {
  const [state, dispatch] = React.useReducer(subjectReducer, {
    subjectSelected: defaultIdSubjectSelected
  });
  return (
    <SubjectStateContext.Provider value={state}>
      <SubjectDispatchContext.Provider value={dispatch}>
        {children}
      </SubjectDispatchContext.Provider>
    </SubjectStateContext.Provider>
  );
}

function useSubjectState() {
  const context = React.useContext(SubjectStateContext);
  if (context === undefined) {
    throw new Error("useSubjectState must be used within a SubjectProvider");
  }
  return context;
}
function useSubjectDispatch() {
  const context = React.useContext(SubjectDispatchContext);
  if (context === undefined) {
    throw new Error("useSubjectDispatch must be used within a SubjectProvider");
  }
  return context;
}

export { SubjectProvider, useSubjectState, useSubjectDispatch };
