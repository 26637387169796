import { combineReducers } from "redux";
import { homeActivityListReducer, homeFolderReducer } from "./modules/home";
import { newsActivityListReducer, newsFolderReducer } from "./modules/news";
import { processingTimeReducer, startDateReducer } from "./modules/time";
import { userReducer } from "./modules/user";

// Model
import { Activity } from "../../models/Activity.model";
import { Folder } from "../../models/folder.model";
import { User } from "../../models/user.model";

// Interface
import { ActivityGroup, FolderGroup } from "./modules/news";
import { errorHandlerReducer, IErrorHandler } from "../errorHandler/reducer";

export interface StoreState {
  processingTime: boolean;
  homeActivityList: Activity[];
  homeFolder: Folder[] | null;
  newsActivityList: ActivityGroup;
  newsFolder: FolderGroup;
  user: User | null;
  startDate: number;
  errorHandler: IErrorHandler;
}

export const reducers = combineReducers<StoreState>({
  processingTime: processingTimeReducer,
  homeActivityList: homeActivityListReducer,
  homeFolder: homeFolderReducer,
  newsActivityList: newsActivityListReducer,
  newsFolder: newsFolderReducer,
  user: userReducer,
  startDate: startDateReducer,
  errorHandler: errorHandlerReducer
});
