// React
import React, { useEffect, useRef } from "react";
// Material
import { makeStyles, createStyles } from "@material-ui/core";
// Component
import YouTube, { Options } from "react-youtube";
// Services
import {
  storeVideoCurrentTime,
  getVideoCurrentTime,
  deleteVideoCurrentTime
} from "../../services/video.service";

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    video: {
      textAlign: "center",
      marginTop: 15,
      width: "100%"
    }
  })
);

/**
 * YouTubePlayer props
 */
interface YouTubePlayerProps {
  handlePlayTimer: (play: boolean, over?: boolean) => void;
  videoId: string;
}

/**
 * Player component
 */
const YouTubePlayer = ({ handlePlayTimer, videoId }: YouTubePlayerProps) => {
  /** Classes */
  const classes = useStyles();

  const video = useRef<any>(null);

  const playerState = useRef<number | null>(null);

  const opts: Options = {
    playerVars: {
      rel: 0,
      autoplay: 1
    }
  };

  const saveVideoTime = () => {
    storeVideoCurrentTime(
      videoId,
      Math.round(video?.current.getCurrentTime() || 0)
    );
  };

  const isPlaying = (): boolean => {
    return YouTube.PlayerState.PLAYING === playerState.current;
  };

  /** Use effect to auto play or not timer */
  useEffect(() => {
    return () => {
      isPlaying() && saveVideoTime();
    };
  }, []);

  return (
    <YouTube
      onReady={e => {
        video.current = e.target;
        const currentTime = getVideoCurrentTime(videoId);
        currentTime && video.current.seekTo(currentTime, true);
      }}
      onStateChange={e => {
        playerState.current = e.data;
        !isPlaying() && saveVideoTime();
      }}
      opts={opts}
      className={classes.video}
      videoId={videoId}
      onPlay={() => handlePlayTimer(true)}
      onPause={() => handlePlayTimer(false)}
      onEnd={() => {
        handlePlayTimer(true, true);
        deleteVideoCurrentTime(videoId);
      }}
    />
  );
};

export default React.memo(YouTubePlayer);
