// React
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Container,
  Grid,
  Typography,
  Button,
  Hidden,
  Theme
} from "@material-ui/core";

// Material
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

// Lib
import { DatePicker } from "@material-ui/pickers";
import { useSnackbar } from "notistack";
import { useUserAuthentication } from "@dsk-lib/user";

// Icon
import GetAppIcon from "@material-ui/icons/GetApp";

// Services
import { downloadCertificate } from "../../services/navigation.service";

// Components
import ColorCircularProgress from "../../features/ColorCircularProgress/ColorCircularProgress";
import { User } from "../../models/user.model";

/**
 * Use styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  datepickerWrapper: {
    marginLeft: -5
  },
  downloadBtnWrapper: {
    marginRight: -15
  },
  appBar: {
    width: "100%",
    paddingBottom: 15,
    paddingTop: 15,
    top: 0,
    left: 0,
    boxShadow: "inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)",
    backgroundColor: "#f9fdfc"
  },
  title: {
    color: "#18202f",
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: -2
  },
  button: {
    height: 46,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      marginLeft: -30
    }
  }
}));

/**
 * CertificatePicker props
 */
interface ICertificatePickerProps {
  disableDate: (date: MaterialUiPickersDate) => boolean;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date | null;
  endDate?: Date | null;
  user?: User | null;
}

/**
 * Certificate picker component
 */
const CertificatePicker = (props: ICertificatePickerProps) => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();

  /** Props */
  const { disableDate, minDate, maxDate, startDate, endDate, user } = props;

  /** Classes */
  const classes = useStyles();

  // Start date
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
    startDate || null
  );
  // End date
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
    endDate || null
  );

  // Offset height
  // TODO: Dynamically set offsetHeight based on AppBar's height
  const [offsetHeight] = useState<number>(110);

  // Is downloading
  const [downloading, setDownloading] = useState<boolean>(false);

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Use Effect Start Date
   */
  useEffect(() => {
    if (startDate && startDate !== selectedStartDate) {
      setSelectedStartDate(startDate);
    }
  }, [startDate]);

  /**
   * Use Effect End Date
   */
  useEffect(() => {
    if (endDate && endDate !== selectedEndDate) {
      setSelectedEndDate(endDate);
    }
  }, [endDate]);

  /**
   * Handle certificate download
   */
  const handleDownload = async () => {
    if (downloading) {
      return;
    }
    setDownloading(true);

    await downloadCertificate(
      fetchWithCredentials,
      selectedStartDate!,
      selectedEndDate!,
      user
    )
      .then(() => {
        setDownloading(false);
        enqueueSnackbar("Attestation téléchargée", {
          variant: "success"
        });
      })
      .catch(() => {
        setDownloading(false);
        enqueueSnackbar(
          "Il y a eu une erreur lors du téléchargement. Veuillez réessayer ultérieurement.",
          {
            variant: "error"
          }
        );
      });
  };

  return (
    <>
      <AppBar position="absolute" className={classes.appBar}>
        <Container style={{ margin: "0 auto" }} maxWidth="md">
          <Typography
            component="p"
            className={classes.title}
            gutterBottom={true}
            noWrap={true}
          >
            Mon attestation
          </Typography>
          <Grid
            className={classes.datepickerWrapper}
            container={true}
            spacing={1}
            alignItems="center"
            justify="space-between"
          >
            <Grid item={true} xs={4}>
              <DatePicker
                variant="dialog"
                label="Début"
                value={selectedStartDate}
                format="DD/MM/YYYY"
                cancelLabel="Annuler"
                shouldDisableDate={disableDate}
                minDate={minDate}
                maxDate={selectedEndDate || maxDate}
                onChange={(date: MaterialUiPickersDate | null) =>
                  setSelectedStartDate(date!.toDate())
                }
              />
            </Grid>
            <Grid item={true} xs={4}>
              <DatePicker
                label="Fin"
                value={selectedEndDate}
                format="DD/MM/YYYY"
                cancelLabel="Annuler"
                shouldDisableDate={disableDate}
                minDate={selectedStartDate || maxDate}
                maxDate={maxDate}
                onChange={(date: MaterialUiPickersDate | null) =>
                  setSelectedEndDate(date!.toDate())
                }
              />
            </Grid>
            <Grid
              className={classes.downloadBtnWrapper}
              item={true}
              xs={2}
              md={3}
            >
              <Button
                color="primary"
                fullWidth={true}
                variant="contained"
                className={classes.button}
                disabled={!selectedStartDate || !selectedEndDate}
                onClick={handleDownload}
              >
                {downloading ? (
                  <ColorCircularProgress ligth={true} />
                ) : (
                  <GetAppIcon />
                )}
                <Hidden smDown={true}>
                  <Typography component="span">&nbsp;Télécharger</Typography>
                </Hidden>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <div style={{ height: offsetHeight }} />
    </>
  );
};

export default React.memo(CertificatePicker);
