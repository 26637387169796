import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ActionTypes } from "../actionTypes";

// Store state
import { StoreState } from "../../reducers";

// Model
import { Activity } from "../../../models/Activity.model";
import { Folder } from "../../../models/folder.model";

// Services
import { getCover, getFolder } from "../../../services/navigation.service";
import { setErrorToHandleError } from "../../errorHandler/actions";

export interface FetchHomeActivityList {
  type: ActionTypes.fetchHomeActivityList;
  payload: Activity[];
}

export interface FetchHomeFolder {
  type: ActionTypes.fetchHomeFolder;
  payload: Folder[] | null;
}

export const fetchHomeActivityList = (
  fetchCredentials: typeof fetch
): ThunkAction<Promise<void>, StoreState, {}, FetchHomeActivityList> => {
  return async (dispatch: Dispatch) => {
    const result = await getCover(fetchCredentials);

    if (result) {
      dispatch<FetchHomeActivityList>({
        type: ActionTypes.fetchHomeActivityList,
        payload: result.data
      });
    }
  };
};

export const fetchHomeFolder = (
  fetchCredentials: typeof fetch
): ThunkAction<Promise<void>, StoreState, {}, FetchHomeFolder> => {
  return async (dispatch: Dispatch) => {
    const result = await getFolder(fetchCredentials).catch(err =>
      dispatch(setErrorToHandleError(true, err.status))
    );

    dispatch<FetchHomeFolder>({
      type: ActionTypes.fetchHomeFolder,
      payload: result.data && result.data.length > 0 ? result.data : null
    });
  };
};
