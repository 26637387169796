/** Constante */
import { CONST } from "../config/constant";

/** Models */
import { User } from "../models/user.model";

/** Store */
import { store } from "../store/createStore";

/**
 * Get full name
 */
export const getFullName = (keycloak: any): string => {
  return keycloak!.tokenParsed.name || "";
};

/**
 * Is admin
 */
export const getIsAdmin = (): boolean => {
  const user: User | null = store.getState().user;
  return user ? user.roles.includes("admin") : false;
};

/**
 * Is super admin
 */
export const getIsSuperAdmin = (): boolean => {
  const user: User | null = store.getState().user;
  return user ? user.roles.includes("super_admin") : false;
};

/**
 * Disconnect user
 */
export const disconnectUser = (keycloak: any) => {
  keycloak && keycloak.logout();
};

/**
 * Get preferred username
 */
export const getPreferredUsername = (keycloak: any): string => {
  return keycloak!.tokenParsed.preferred_username || "";
};

/**
 * Get user's informations
 */
export const getUser = async (fetchCredentials: typeof fetch) => {
  /** URL user information */
  let URL_USER_INFORMATION: string = `${process.env.REACT_APP_API_URL_EL}${CONST.API.USER}`;

  const result = await fetchCredentials(URL_USER_INFORMATION);

  return await result.json();
};

/**
 * Get user's newsletter
 */
export const getUserSurvey = async (
  fetchCredentials: typeof fetch
): Promise<boolean> => {
  const URL_USER_SURVEY: string = `${process.env.REACT_APP_API_URL_EL}${CONST.API.SURVEY}`;
  const result = await fetchCredentials(URL_USER_SURVEY);
  return await result
    .json()
    .then(
      res => res?.data?.subscribed === true || res?.data?.subscribed === null
    );
};

/**
 * Patch user newsletter
 * choice : boolean
 */
export const changeUserSurvey = async (
  fetchCredentials: typeof fetch,
  choice: boolean
) => {
  const param = {
    choice
  };
  const URL_USER_SURVEY: string = `${process.env.REACT_APP_API_URL_EL}${CONST.API.SURVEY}`;
  const result = await fetchCredentials(URL_USER_SURVEY, {
    method: "PATCH",
    body: JSON.stringify(param)
  });
  return await result.json();
};
