// React
import React, { useEffect, useRef, useState } from "react";

// Material
import { Button, Grid, Paper, Popover, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

// Typeform
import "@typeform/embed/build/css/widget.css";
import { createWidget } from "@typeform/embed";

// Constants
import { CONST } from "../../config/constant";
import { useUserAuthentication } from "@dsk-lib/user";
import { changeUserSurvey, getUserSurvey } from "../../services/user.service";
import {
  getItemLocalStorage,
  setItemLocalStorage
} from "../../services/utility.service";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "2001!important" as any
    },
    modalSurvey: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "2001!important" as any
    },
    closeIcon: {
      cursor: "pointer",
      position: "absolute",
      top: "4vh",
      right: "4vw",
      zIndex: "2003!important" as any
    },
    modalContent: {
      padding: theme.spacing(5),
      minWidth: "40vw",
      maxWidth: "95vw",
      maxHeight: "95vh"
    },
    modalSurveyContent: {
      backgroundColor: "transparent",
      width: "95vw",
      height: "95vh"
    },
    popover: {
      zIndex: "2002!important" as any
    },
    popoverItem: {
      color: "red"
    },
    buttonModal: {
      margin: "5px 0 5px 12px"
    },
    typography: {
      marginBottom: "15px",
      maxWidth: "500px"
    }
  })
);

/**
 * Modal display mode
 */
enum ModalMode {
  UserChoice = "choice",
  Survey = "survey",
  Confirmation = "confirmation"
}

/**
 * ConfirmModal component
 */
const SurveyModal = () => {
  /**Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();
  /** Classes  */
  const classes = useStyles();
  /** Dom components  */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const surveyContainer = useRef<HTMLElement>(null);
  /** Modals mode */
  const [mode, setMode] = useState<ModalMode | null>(null);
  /** Check if survey started */
  const [isSurveyStarted, setSurveyStarted] = useState<boolean>(false);

  useEffect(() => {
    getUserSurvey(fetchWithCredentials).then(res => {
      if (res === true) {
        if (getItemLocalStorage("EC SURVEY STARTED") === true) {
          setSurveyStarted(true);
        }
        setMode(ModalMode.UserChoice);
      }
    });
  }, []);

  // We need to wait for the modal to be renderer or the ref will always be null
  const createSurvey = () => {
    createWidget(CONST.SURVEY_ID, {
      onSubmit: () => {
        changeUserSurvey(fetchWithCredentials, false);
        localStorage.removeItem("EC SURVEY STARTED");
        setMode(ModalMode.Confirmation);
      },
      container: surveyContainer.current!
    });
  };

  const handleClose = (save: boolean) => {
    if (save) {
      changeUserSurvey(fetchWithCredentials, false);
      localStorage.removeItem("EC SURVEY STARTED");
    }
    setMode(null);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={mode === ModalMode.UserChoice}
        onClose={() => handleClose(false)}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={mode === ModalMode.UserChoice}>
          <React.Fragment>
            <Paper className={classes.modalContent}>
              <Grid container={true} spacing={3} direction="column">
                <Grid item={true}>
                  <Typography variant="h5" component="h3">
                    Enquête de satisfaction
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography component="div" className={classes.typography}>
                    Afin de nous aider à améliorer la qualité de nos services et
                    évaluer votre satisfaction, nous vous proposons de consacrer
                    2 minutes à un questionnaire.
                  </Typography>
                </Grid>
                <Grid
                  item={true}
                  container={true}
                  spacing={3}
                  direction="row"
                  alignContent="center"
                  justify="flex-end"
                  wrap="wrap-reverse"
                >
                  <Popover
                    className={classes.popover}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                  >
                    <Grid container={true} direction="column">
                      <Button variant="text" onClick={() => handleClose(false)}>
                        Me le rappeler plus tard
                      </Button>
                      <Button variant="text" onClick={() => handleClose(true)}>
                        Ne plus me le rappeler
                      </Button>
                    </Grid>
                  </Popover>
                  <Button
                    className={classes.buttonModal}
                    variant="text"
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      setAnchorEl(event.currentTarget)
                    }
                  >
                    Pas maintenant...
                  </Button>
                  <Button
                    className={classes.buttonModal}
                    variant="contained"
                    color="primary"
                    style={{ color: "#FFF" }}
                    onClick={() => {
                      setMode(ModalMode.Survey);
                      setItemLocalStorage("EC SURVEY STARTED", true);
                    }}
                  >
                    {isSurveyStarted ? "Continuer" : "Démarrer"} le
                    questionnaire
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalSurvey}
        open={mode === ModalMode.Survey}
        disableBackdropClick
        closeAfterTransition={true}
        onRendered={createSurvey}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={mode === ModalMode.Survey}>
          <React.Fragment>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => handleClose(false)}
              htmlColor="white"
            />
            <Paper
              className={classes.modalSurveyContent}
              ref={surveyContainer}
            />
          </React.Fragment>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={mode === ModalMode.Confirmation}
        onClose={() => handleClose(false)}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={mode === ModalMode.Confirmation}>
          <React.Fragment>
            <Paper className={classes.modalContent}>
              <Grid container={true} spacing={3} direction="column">
                <Grid item={true}>
                  <Typography variant="h5" component="h3">
                    Enquête de satisfaction
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography component="div" className={classes.typography}>
                    Merci pour votre participation.
                  </Typography>
                </Grid>
                <Grid
                  item={true}
                  container={true}
                  spacing={3}
                  direction="row"
                  alignContent="center"
                  justify="flex-end"
                  wrap="wrap-reverse"
                >
                  <Button
                    className={classes.buttonModal}
                    variant="contained"
                    color="primary"
                    style={{ color: "#FFF" }}
                    onClick={() => handleClose(false)}
                  >
                    Fermer
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        </Fade>
      </Modal>
    </>
  );
};

export default React.memo(SurveyModal);
