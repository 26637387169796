import { Time } from "./time.model";
import { Domain } from "./domain.model";
import { DomainId } from "./category.model";

/**
 * Activity interface
 */
export interface Activity {
  content: string;
  id: number;
  slug: string;
  name: string;
  mediaLinks?: MediaLink[];
  description: string | null;
  date?: number;
  createdAt: number;
  updatedAt: number;
  status: string;
  time: Time;
  publishedAt: string;
  khronosId?: number | null;
  nature: string;
  type: string;
  position: number | null;
  uniqueId: string;
  state?: keyof typeof ActivityState | null;
  domain: Domain;
  questionProgress?: number;
  validatedQuestionCount?: number;
  questionCount?: number;
  validatedScore?: number;
  totalScore?: number;
  blocId?: number;
  youtubeId?: string;
  subjectId?: DomainId;
  started?: boolean;
  report?: IQuestionReport[];
}

/**
 * QuizStarterList interface
 */
export interface QuizStarterList {
  [key: number]: QuizStarter;
}

/**
 * QuizStarter interface
 */
export interface QuizStarter {
  id: string;
  validatedQuestionCount: number;
}

/**
 * Activity status enum
 */
export enum ActivityState {
  UPDATE = "Mise à jour",
  NEW = "Nouveauté"
}

/**
 * MediaLink model
 */
export interface MediaLink {
  type: string;
  id: number;
  media: {
    id: number;
    okulusId: string | null;
    name: string | null;
    mimeType: string | null;
    provider: string | null;
    url: string | null;
    type: string | null;
    data: MetaVideo | null;
    contentType: string | null;
  };
}
/**
 * MetaVideo model
 */
export interface MetaVideo {
  html: string;
  type: string;
  title: string;
  width: number;
  height: number;
  version: string;
  author_url: string;
  author_name: string;
  provider_url: string;
  provider_name: string;
  thumbnail_url: string;
  thumbnail_width: number;
  thumbnail_height: number;
}

/**
 * Question report Interface
 */
export interface IQuestionReport {
  id?: number;
  label: string;
  progress: number;
}
