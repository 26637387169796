// React
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ContentLoader from "react-content-loader";

// Material
import {
  Card,
  CardContent,
  Chip,
  Typography,
  Grid,
  CircularProgress,
  Hidden,
  LinearProgress,
  CardActions,
  Paper
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles
} from "@material-ui/core/styles";

// Images
import clockIcon from "../../assets/ic-estime.svg";
import GetAppIcon from "@material-ui/icons/GetApp";

// Helpers
import { isPlurial } from "../../helpers/card.helper";
import { secondsToMinutes } from "../../helpers/date-fomatter.helper";
import clsx from "clsx";

// Colors
import { grey } from "@material-ui/core/colors";
import { color } from "../../styles/color";

import "../../styles/progressbar.scss";

// Models
import { Folder } from "../../models/folder.model";

import ReactGA from "react-ga";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    noEvent: {
      pointerEvents: "none"
    },
    wrapper: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    wrapperContent: {
      margin: theme.spacing(1),
      position: "relative"
    },
    card: {
      [theme.breakpoints.down("sm")]: {
        borderTop: "10px outset"
      },
      [theme.breakpoints.up("md")]: {
        borderLeft: "10px outset"
      },
      width: "100%",
      padding: theme.spacing(0.5, 0.5),
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f0f0f0"
      }
    },
    chip: {
      color: "white",
      margin: theme.spacing(0.5)
    },
    title: {
      fontSize: 16,
      justifyContent: "center",
      fontWeight: "bold"
    },
    subTitle: {
      fontSize: 16,
      justifyContent: "center"
    },
    titleTime: {
      fontWeight: 500
    },
    circularLoader: {
      color: grey[300],
      zIndex: 1
    },
    circular: {
      position: "absolute",
      color: theme.palette.primary.main,
      zIndex: 2
    },
    circularBackground: {
      color: grey[300],
      zIndex: 1
    },
    circulartext: {
      position: "absolute",
      top: 14,
      width: 50,
      height: 20,
      textAlign: "center",
      display: "block",
      color: theme.palette.primary.main,
      zIndex: 2
    },
    container: {
      minWidth: 320,
      maxWidth: 1000,
      margin: "0 auto"
    },
    linearProgressBar: {
      root: {
        height: 10,
        backgroundColor: theme.palette.primary.main
      },
      bar: {
        borderRadius: 20,
        backgroundColor: theme.palette.primary.main
      }
    },
    time: {
      color: theme.palette.primary.main,
      margin: theme.spacing(1)
    },
    verticaleLine: {
      width: 1,
      border: "solid 1px rgba(0, 0, 0, 0.2)",
      marginLeft: 16,
      marginRight: 16
    },
    cardDownload: {
      "&:hover": {
        backgroundColor: "#f0f0f0"
      },
      cursor: "pointer",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        borderTop: "1px dashed gray"
      },
      [theme.breakpoints.up("md")]: {
        borderLeft: "1px dashed gray"
      }
    },
    iconDownload: {
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1),
        justifyContent: "flex-end"
      },
      [theme.breakpoints.up("md")]: {
        justifyContent: "center"
      }
    },
    downloadText: {
      margin: theme.spacing(1),
      lineHeight: 20,
      display: "inline-block"
    },
    maxHeight: {
      height: "100%"
    },
    locked: {
      pointerEvents: "none",
      opacity: 0.5,
      cursor: "not-allowed"
    }
  })
);

/**
 * Border linear progress
 */
const BorderLinearProgress = withStyles({
  root: {
    width: 50,
    height: 8,
    borderRadius: 20,
    backgroundColor: "#dddfdf"
  },
  bar: {
    borderRadius: 20
  }
})(LinearProgress);

/**
 * Floader loader
 */
const FolderLoader = () => (
  <ContentLoader
    height={160}
    width={920}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#e3e3e3"
  >
    <rect x="20" y="20" rx="5" ry="5" width="650" height="25" />
    <rect x="20" y="50" rx="5" ry="5" width="200" height="25" />
    <rect x="20" y="80" rx="5" ry="5" width="650" height="25" />
    <rect x="20" y="120" rx="5" ry="5" width="350" height="25" />
  </ContentLoader>
);

/**
 * Fake card folder
 */
const FakeCardFolder = () => {
  /** classes */
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${classes.noEvent}`}>
      <Grid container={true} alignItems={"center"} spacing={2} wrap={"nowrap"}>
        <Grid item={true} className={classes.wrapper}>
          <div className={classes.wrapperContent}>
            <CircularProgress
              size={50}
              variant="indeterminate"
              value={0}
              thickness={4}
              className={classes.circularLoader}
            />
          </div>
        </Grid>
        <Grid container={true} item={true} xs={true}>
          <Grid item={true} md={true} xs={12}>
            <Card className={classes.card}>
              <FolderLoader />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

/**
 * Card folder props
 */
interface CardFolderProps {
  folder: Folder;
  fake?: boolean;
  locked?: boolean;
  onLockedClick?: () => void;
}
/**
 * Card folder
 */
const CardFolder = (props: RouteComponentProps & CardFolderProps) => {
  const { match, history, folder, fake, locked, onLockedClick } = props;

  /** classes */
  const classes = useStyles();

  /** progress folder */
  const [progress, setProgress] = React.useState(0);

  /**
   * Open file
   * @param file
   */
  const openPDF = (file: string): void => {
    ReactGA.event({
      category: "PDF",
      action: "Téléchargé",
      label: `Dossier d'actualités ${folder.domain.name}`
    });
    window.open(file, "_blank", "noopener");
  };

  /**
   * Open folder
   * @param idFolder
   */
  const openFolder = (idFolder: number): void => {
    history.push(match.url + "/dossier-actualites/" + idFolder);
  };

  /**
   * Use effect progress
   */
  React.useEffect(() => {
    setProgress(folder.progress);
  }, [folder.progress]);

  return (
    <div
      onClick={() => locked && onLockedClick && onLockedClick()}
      className={classes.container}
    >
      {fake ? (
        <FakeCardFolder />
      ) : (
        <Grid
          container={true}
          alignItems={"center"}
          spacing={2}
          wrap={"nowrap"}
        >
          <Grid item={true} className={classes.wrapper}>
            <div className={classes.wrapperContent}>
              <span className={classes.circulartext}>{progress}%</span>
              <CircularProgress
                size={50}
                variant="static"
                value={progress}
                thickness={4}
                className={classes.circular}
              />
              <CircularProgress
                size={50}
                variant="static"
                value={100}
                className={classes.circularBackground}
              />
            </div>
          </Grid>
          <Grid container={true} item={true} xs={true}>
            <Grid
              className={clsx({
                [classes.locked]: locked
              })}
              item={true}
              md={true}
              xs={12}
              onClick={() => openFolder(folder.id)}
            >
              <Card
                className={classes.card}
                style={{
                  borderTopColor: `${color[folder.domain.id]}`,
                  borderLeftColor: `${color[folder.domain.id]}`
                }}
              >
                <CardContent>
                  <Grid container={true} alignItems={"center"}>
                    <Grid item={true} xs={12}>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom={true}
                      >
                        {folder.name}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <Chip
                        size="small"
                        label={folder.domain.name}
                        className={classes.chip}
                        style={{
                          backgroundColor: `${color[folder.domain.id]}`
                        }}
                      />
                    </Grid>
                    <Hidden mdUp={true} implementation="css">
                      <Grid
                        container={true}
                        alignItems={"center"}
                        direction="row"
                        justify="center"
                        spacing={2}
                      >
                        <Grid
                          item={true}
                          container={true}
                          xs={true}
                          justify="flex-start"
                          alignItems={"center"}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={progress}
                            color="primary"
                          />
                          <Typography noWrap={true} className={classes.time}>
                            {progress}%
                          </Typography>
                        </Grid>
                      </Grid>
                    </Hidden>
                    <Grid item={true} xs={12}>
                      <Typography
                        className={classes.subTitle}
                        color="textPrimary"
                        gutterBottom={true}
                      >
                        {folder.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid
                    container={true}
                    justify="flex-start"
                    alignContent={"center"}
                    spacing={1}
                  >
                    <Grid item={true}>
                      <img src={clockIcon} alt="temps estimé" />
                    </Grid>
                    <Grid item={true}>
                      <Typography
                        className={classes.subTitle}
                        color="textSecondary"
                        gutterBottom={true}
                        noWrap={true}
                      >
                        Temps estimé
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={true}>
                      <Typography
                        className={`${classes.subTitle} ${classes.titleTime}`}
                        gutterBottom={true}
                      >
                        {secondsToMinutes(folder.estimatedTime)} min
                        <span className={classes.verticaleLine} />
                        {folder.nbActivities} {isPlurial(folder.nbActivities)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
            {folder && folder.file ? (
              <Grid
                item={true}
                md={3}
                xs={12}
                alignItems="stretch"
                container={true}
                onClick={() => openPDF(folder.file)}
              >
                <Paper className={classes.cardDownload}>
                  <Grid
                    container={true}
                    alignContent={"center"}
                    alignItems={"center"}
                    className={classes.maxHeight}
                  >
                    <Grid
                      container={true}
                      item={true}
                      xs={2}
                      md={3}
                      justify="center"
                      className={classes.iconDownload}
                    >
                      <GetAppIcon />
                    </Grid>
                    <Grid
                      container={true}
                      item={true}
                      xs={9}
                      md={9}
                      justify="center"
                      wrap="nowrap"
                    >
                      Télécharger le dossier
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default withRouter(React.memo(CardFolder));
