// React
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import ContentLoader from "react-content-loader";

// Material
import {
  Card,
  CardContent,
  Chip,
  Typography,
  Grid,
  CircularProgress,
  Hidden,
  LinearProgress,
  CardActions,
  Paper,
  IconButton,
  Snackbar
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles
} from "@material-ui/core/styles";

// Services
import { getIsSuperAdmin } from "../../services/user.service";
import { getActivityWordFile } from "../../services/navigation.service";
import { getQuizStarter } from "../../services/quiz.service";

// Colors
import { color } from "../../styles/color";
import { yellow, grey } from "@material-ui/core/colors";

// Images
import clockIcon from "../../assets/ic-estime.svg";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";

// Models
import { ActivityState, Activity } from "../../models/Activity.model";
import { DomainId } from "../../models/category.model";

// Helpers
import { cleanAccentLabel } from "../../helpers/card.helper";
import {
  formattedDate,
  secondsToMinutes
} from "../../helpers/date-fomatter.helper";
import clsx from "clsx";

import "../../styles/progressbar.scss";

// Providers
import { useActivityDispatch } from "../../shared/Activity.provider";

// Components
import TimeIndicator from "../../features/TimeIndicator/TimeIndicator";
import ColorCircularProgress from "../../features/ColorCircularProgress/ColorCircularProgress";

// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noEvent: {
      pointerEvents: "none"
    },
    cardContent: {
      paddingBottom: 0
    },
    cardActions: {
      paddingTop: 0
    },
    dot: {
      margin: 1,
      marginRight: 10,
      width: 8,
      height: 8,
      borderRadius: "50%",
      display: "inline-block"
    },
    dotUpdate: {
      backgroundColor: yellow[600]
    },
    dotNew: {
      backgroundColor: theme.palette.primary.main
    },
    wrapper: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    wrapperContent: {
      margin: theme.spacing(1),
      position: "relative"
    },
    card: {
      padding: theme.spacing(0.5, 0.5),
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f0f0f0"
      }
    },
    chipDomain: {
      color: "white",
      marginBottom: theme.spacing(0.5)
    },
    chipNature: {
      backgroundColor: "#565554",
      color: "white",
      margin: theme.spacing(0.5),
      textTransform: "capitalize"
    },
    chipType: {
      backgroundColor: "white",
      color: "black",
      margin: theme.spacing(0.5),
      textTransform: "capitalize"
    },
    title: {
      fontSize: 16,
      justifyContent: "center"
    },
    titleTime: {
      fontWeight: 500
    },
    cardFooter: {
      margin: theme.spacing(0.5)
    },
    clockIcon: {
      display: "flex"
    },
    stateType: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start"
      }
    },
    date: {
      fontSize: 14
    },
    circularLoader: {
      color: grey[300],
      zIndex: 1
    },
    circulartext: {
      position: "absolute",
      top: 14,
      width: 50,
      height: 20,
      textAlign: "center",
      display: "block",
      color: theme.palette.primary.main,
      zIndex: 2
    },
    circular: {
      position: "absolute",
      color: theme.palette.primary.main,
      zIndex: 2
    },
    circularBackground: {
      color: grey[300],
      zIndex: 1
    },
    progressContainer: {
      position: "relative"
    },
    container: {
      minWidth: 320,
      maxWidth: 1000,
      margin: "0 auto"
    },
    linearProgressBar: {
      root: {
        height: 10,
        backgroundColor: theme.palette.primary.main
      },
      bar: {
        borderRadius: 20,
        backgroundColor: theme.palette.primary.main
      }
    },
    time: {
      color: theme.palette.primary.main,
      margin: theme.spacing(1)
    },
    cardDownload: {
      "&:hover": {
        backgroundColor: "#f0f0f0"
      },
      cursor: "pointer",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        borderTop: "1px dashed gray"
      },
      [theme.breakpoints.up("md")]: {
        borderLeft: "1px dashed gray"
      }
    },
    iconDownload: {
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1),
        justifyContent: "flex-end"
      },
      [theme.breakpoints.up("md")]: {
        justifyContent: "center"
      }
    },
    downloadText: {
      margin: theme.spacing(1),
      lineHeight: 20,
      display: "inline-block"
    },
    maxHeight: {
      height: "100%"
    },
    locked: {
      pointerEvents: "none",
      opacity: 0.5,
      cursor: "not-allowed"
    }
  })
);

/**
 * Border linear progress
 */
const BorderLinearProgress = withStyles({
  root: {
    width: 50,
    height: 8,
    borderRadius: 20,
    backgroundColor: "#dddfdf"
  },
  bar: {
    borderRadius: 20
  }
})(LinearProgress);

const getActivityState = (
  state?: keyof typeof ActivityState | null
): string | null => {
  return state ? ActivityState[state] : null;
};
/**
 * Card activity props
 */
interface CardActivityProps {
  activity: Activity;
  fake?: boolean;
  locked?: boolean;
  onLockedClick?: () => void;
}

/**
 * Card activity
 */
const CardActivity = (props: RouteComponentProps & CardActivityProps) => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();

  /** Props */
  const { match, activity, history, fake, locked, onLockedClick } = props;

  /** Classes  */
  const classes = useStyles();
  /** progress folder */
  const [progress, setProgress] = React.useState(0);
  /** isSuperAdmin */
  const [isSuperAdmin, setIsSuperAdmin] = React.useState<boolean>(false);
  /** loadingWordFile */
  const [isLoadingWordFile, setIsLoadingWordFile] = React.useState(false);
  /** errorWordFile */
  const [isErrorWordFile, setIsErrorWordFile] = React.useState(false);

  const dispatch = useActivityDispatch();

  /**
   * Open activity
   */
  const openActivity = (): void => {
    const starterQuiz = getQuizStarter(activity.domain.id as DomainId);
    if (starterQuiz) {
      history.push(match.url + "/activite/" + starterQuiz.id + "?staterInfo=1");
      return;
    }
    history.push(match.url + "/activite/" + activity.uniqueId);
    const myElement = document.getElementById(activity.uniqueId.toString());
    const activityListElement = document.getElementById("activityList");
    if (myElement && activityListElement) {
      dispatch({
        type: "setIdActivitySelected",
        idActivitySelected: `${activity.id.toString()};
        ${myElement.getBoundingClientRect().top -
          activityListElement.getBoundingClientRect().top}`
      });
    }
  };

  /**
   * Is quiz
   * @param uniqueId
   */
  const isQuiz = (uniqueId: string): boolean => {
    return uniqueId.substring(0, uniqueId.indexOf("_")) === "quiz";
  };

  /**
   * Get activity as word file
   */
  const fetchWordFile = async () => {
    setIsLoadingWordFile(true);
    if (isQuiz(activity.uniqueId)) {
      if (
        await getActivityWordFile(fetchWithCredentials, "quizzes", activity)
      ) {
        setIsLoadingWordFile(false);
        return;
      }
      setIsLoadingWordFile(false);
      setIsErrorWordFile(true);
    } else {
      if (
        await getActivityWordFile(fetchWithCredentials, "lessons", activity)
      ) {
        setIsLoadingWordFile(false);
        return;
      }
      setIsLoadingWordFile(false);
      setIsErrorWordFile(true);
    }
  };

  /**
   * Close snack bar
   */
  const handleClose = (
    _event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsErrorWordFile(false);
  };

  /**
   * Manage isAdmin
   */
  React.useEffect(() => {
    setIsSuperAdmin(getIsSuperAdmin());
  }, []);

  /**
   * Use effect progress
   */
  React.useEffect(() => {
    const progress = isQuiz(activity.uniqueId)
      ? activity.questionProgress!
      : activity.time.progress;
    setProgress(Math.round(progress));
  }, [activity.time.progress, activity.validatedQuestionCount]);

  return (
    <div
      onClick={() => locked && onLockedClick && onLockedClick()}
      className={classes.container}
    >
      {fake ? (
        <FakeCardActivity />
      ) : (
        <React.Fragment>
          <Grid
            id={activity.uniqueId}
            container={true}
            alignItems={"center"}
            spacing={2}
            wrap={"nowrap"}
          >
            <Grid item={true} className={classes.wrapper}>
              <div className={classes.wrapperContent}>
                <span className={classes.circulartext}>{progress}%</span>
                <CircularProgress
                  size={50}
                  variant="static"
                  value={progress}
                  thickness={4}
                  className={classes.circular}
                />
                <CircularProgress
                  size={50}
                  variant="static"
                  value={100}
                  className={classes.circularBackground}
                />
              </div>
            </Grid>
            <Grid container={true} item={true} xs={true}>
              <Grid
                className={clsx({
                  [classes.locked]: locked
                })}
                item={true}
                md={true}
                xs={12}
                onClick={() => openActivity()}
              >
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Hidden mdUp={true} implementation="css">
                      <Grid
                        container={true}
                        alignItems={"center"}
                        direction="row"
                        justify="flex-start"
                        spacing={2}
                      >
                        <Grid item={true}>
                          <Typography
                            noWrap={true}
                            className={classes.date}
                            color="textSecondary"
                          >
                            {formattedDate((activity.date || 0) * 1000)}
                          </Typography>
                        </Grid>
                        <Grid
                          item={true}
                          container={true}
                          xs={true}
                          justify="flex-start"
                          alignItems={"center"}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={progress}
                            color="primary"
                          />
                          <Typography noWrap={true} className={classes.time}>
                            {progress}%
                          </Typography>
                        </Grid>
                      </Grid>
                    </Hidden>
                    <Grid container={true}>
                      <Grid item={true}>
                        <Chip
                          size="small"
                          label={activity.domain.name}
                          className={classes.chipDomain}
                          style={{
                            backgroundColor: `${color[activity.domain.id]}`
                          }}
                        />
                        {activity.nature ? (
                          <Chip
                            size="small"
                            label={cleanAccentLabel(activity.nature)}
                            className={classes.chipNature}
                            variant="outlined"
                          />
                        ) : null}
                        {activity.type ? (
                          <Chip
                            size="small"
                            label={cleanAccentLabel(activity.type)}
                            className={classes.chipType}
                            variant="outlined"
                          />
                        ) : null}
                      </Grid>
                      <Grid
                        container={true}
                        item={true}
                        xs={true}
                        alignItems={"center"}
                        justify={"flex-end"}
                      >
                        <Hidden smDown={true} implementation="css">
                          <Typography
                            noWrap={true}
                            className={classes.date}
                            color="textSecondary"
                          >
                            {formattedDate((activity.date || 0) * 1000)}
                          </Typography>
                        </Hidden>
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Typography
                          className={classes.title}
                          color="textPrimary"
                          gutterBottom={true}
                        >
                          {activity.status === "draft" ||
                          activity.status === "validated"
                            ? "[BROUILLON] " + activity.name
                            : activity.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Grid
                      container={true}
                      justify="flex-start"
                      spacing={1}
                      className={classes.cardFooter}
                    >
                      <Grid item={true} className={classes.clockIcon}>
                        <img src={clockIcon} alt="temps estimé" />
                      </Grid>
                      <Grid
                        container={true}
                        item={true}
                        xs={9}
                        alignItems="center"
                      >
                        <TimeIndicator
                          isCategory={false}
                          idDomain={String(activity.domain.id)}
                          confirmedTime={`${secondsToMinutes(
                            activity.time.confirmed
                          )} min`}
                          estimedTime={`${secondsToMinutes(
                            activity.time.estimated
                          )} min`}
                          isQuiz={isQuiz(activity.uniqueId)}
                          validatedQuestionCount={
                            activity.validatedQuestionCount
                          }
                          questionCount={activity.questionCount}
                        />
                      </Grid>
                      {activity.state ? (
                        <Grid
                          className={classes.stateType}
                          container={true}
                          item={true}
                          xs
                          alignItems="center"
                          justify="flex-end"
                        >
                          <Typography
                            className={classes.date}
                            gutterBottom={true}
                            noWrap={true}
                          >
                            <span
                              className={`${classes.dot} ${
                                activity.state === "UPDATE"
                                  ? classes.dotUpdate
                                  : classes.dotNew
                              }`}
                            />
                            {getActivityState(activity.state)}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>

              {isSuperAdmin ? (
                <Grid
                  item={true}
                  md={3}
                  xs={12}
                  alignItems="stretch"
                  container={true}
                  onClick={() => fetchWordFile()}
                >
                  <Paper className={classes.cardDownload}>
                    <Grid
                      container={true}
                      alignContent={"center"}
                      alignItems={"center"}
                      className={classes.maxHeight}
                    >
                      <Grid
                        container={true}
                        item={true}
                        xs={2}
                        md={3}
                        justify="center"
                        className={classes.iconDownload}
                      >
                        {isLoadingWordFile ? (
                          <ColorCircularProgress />
                        ) : (
                          <GetAppIcon />
                        )}
                      </Grid>
                      <Grid
                        container={true}
                        item={true}
                        xs={9}
                        md={9}
                        justify="center"
                        wrap="nowrap"
                      >
                        Télécharger le Word
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={isErrorWordFile}
            autoHideDuration={6000}
            onClose={handleClose}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={
              <span id="message-id">
                Il y a eu une erreur lors du téléchargement. Veuillez réessayer
                ultérieurement.
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </React.Fragment>
      )}
    </div>
  );
};

/**
 * Activity loader
 */
const ActivityLoader = () => (
  <ContentLoader
    height={160}
    width={920}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#e3e3e3"
  >
    <rect x="20" y="20" rx="5" ry="5" width="300" height="25" />
    <rect x="800" y="20" rx="5" ry="5" width="100" height="25" />
    <rect x="20" y="50" rx="5" ry="5" width="650" height="25" />
    <rect x="20" y="120" rx="5" ry="5" width="150" height="25" />
  </ContentLoader>
);

/**
 * Fake card activity
 */
const FakeCardActivity = () => {
  /** classes */
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${classes.noEvent}`}>
      <Grid container={true} alignItems={"center"} spacing={2} wrap={"nowrap"}>
        <Grid item={true} className={classes.wrapper}>
          <div className={classes.wrapperContent}>
            <CircularProgress
              size={50}
              variant="indeterminate"
              value={0}
              thickness={4}
              className={classes.circularLoader}
            />
          </div>
        </Grid>
        <Grid container={true} item={true} xs={true}>
          <Grid item={true} md={true} xs={12}>
            <Card className={classes.card}>
              <ActivityLoader />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(CardActivity);
