import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

// Actions
import { ActionTypes } from "../actionTypes";

// Store state
import { StoreState } from "../../reducers";

// Model
import { User } from "../../../models/user.model";

// Services
import { getUser } from "../../../services/user.service";

export interface FetchUser {
  type: ActionTypes.fetchUser;
  payload: User | null;
}

const setUserSuccess = (user: User): FetchUser => {
  return {
    type: ActionTypes.fetchUser,
    payload: user
  };
};

export const fetchUser = (
  fetchWithCredentials: typeof fetch
): ThunkAction<Promise<void>, StoreState, {}, FetchUser> => {
  return async (dispatch: Dispatch) => {
    const result = await getUser(fetchWithCredentials);
    const user = result.data;
    dispatch<FetchUser>(setUserSuccess(user || null));
  };
};
