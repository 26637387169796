import { Action, ActionTypes } from "../../actions";

// Model
import { Activity } from "../../../models/Activity.model";
import { Folder } from "../../../models/folder.model";

export const homeActivityListReducer = (
  state: Activity[] = [],
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.fetchHomeActivityList:
      return action.payload;
    default:
      return state;
  }
};

export const homeFolderReducer = (
  state: Folder[] | null = null,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.fetchHomeFolder:
      return action.payload;
    default:
      return state;
  }
};
