// React
import React, { useEffect } from "react";
// Material
import { Container } from "@material-ui/core";
// Component
import Viewers from "../../component/Viewer/Viewers";
import YouTubePlayer from "../../features/Video/YouTubePlayer";
// Styles
import "./Lessons.scss";
// Models
import { Activity } from "../../models/Activity.model";
// Helpers
import { validURL } from "../../helpers/url.helper";

/**
 * Lesson props
 */
interface LessonProps {
  handlePlayTimer: (play: boolean) => void;
  activity: Activity;
  fontSize: number;
}

/**
 * Lesson component
 */
const Lessons = ({ handlePlayTimer, activity, fontSize }: LessonProps) => {
  /** Is video */
  const isVideo: boolean = activity.nature === "VIDEO";

  /** Use effect to auto play or not timer */
  useEffect(() => {
    handlePlayTimer(!isVideo);
  }, []);

  if (validURL(activity.content)) {
    return (
      <div>
        <Viewers url={activity.content} />
      </div>
    );
  } else {
    return (
      <Container maxWidth="md">
        <div className="lesson-content" style={{ fontSize: `${fontSize}px` }}>
          {isVideo ? (
            <YouTubePlayer
              videoId={activity.youtubeId || ""}
              handlePlayTimer={handlePlayTimer}
            />
          ) : activity.content && !validURL(activity.content) ? (
            <div dangerouslySetInnerHTML={{ __html: activity.content }} />
          ) : null}
        </div>
      </Container>
    );
  }
};

export default React.memo(Lessons);
