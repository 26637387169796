import { AxiosInstance, AxiosRequestConfig } from "axios";
import { useState, useEffect } from "react";

const useHttpErrorHandler = (httpClient: AxiosInstance) => {
  const [error, setError] = useState<any>(undefined);

  const requestInterceptor: number = httpClient.interceptors.request.use(
    (request: AxiosRequestConfig) => {
      setError(undefined);
      return request;
    }
  );

  const responseInterceptor: number = httpClient.interceptors.response.use(
    response => response,
    (error: any) => {
      const status = error.response.status;
      if (status === 405 || status === 500) {
        setError(error);
      } else {
        setError(undefined);
      }
    }
  );

  useEffect(
    () => () => {
      httpClient.interceptors.request.eject(requestInterceptor);
      httpClient.interceptors.response.eject(responseInterceptor);
    },
    [requestInterceptor, responseInterceptor]
  );

  return error;
};

export default useHttpErrorHandler;
