// React
import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

// Material
import {
  Container,
  Button,
  Grid,
  Typography,
  Theme,
  Box,
  Paper
} from "@material-ui/core";

// Components
import { QuizPlayer } from "@dsk-lib/quizplayer";
import StarterBilan from "./Bilan/StarterBilan";

// Styles
import "./Quiz.scss";
import icon from "./assets/spacer-note.svg";
import { makeStyles, createStyles } from "@material-ui/styles";

// Models
import { Activity } from "../../models/Activity.model";
import { DomainId } from "../../models/category.model";

// Services
import {
  getActivity,
  postValidedQuestions,
  resetAnswers
} from "../../services/navigation.service";

// Libs
import { useUserAuthentication } from "@dsk-lib/user";
import { increaseQuizStarterValidatedQuestionCount } from "../../services/quiz.service";
import QuizStarterCard from "./QuizStarterCard";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disable: {
      opacity: 0.3,
      cursor: "wait !important"
    },
    correctionView: {
      background: "#f1f4f5",
      textAlign: "center"
    },
    title: {
      fontSize: "15vw",
      fontWeight: 800,
      color: "#ebedee",
      margin: "0 auto"
    },
    subTitle: {
      fontSize: 28,
      fontWeight: 900,
      color: "#18202f",
      marginTop: -55
    },
    correctionScore: {
      fontSize: 57.9,
      lineHeight: 1.2
    },
    ratio: {
      color: theme.palette.primary.main,
      fontWeight: 700
    },
    separatorScore: {
      width: 67.9,
      height: 21.1,
      background: "url('./assets/spacer-note.svg') no-repeat",
      margin: "auto"
    },
    correctionComment: {
      fontSize: 20,
      lineHeight: 1.1,
      textAlign: "center",
      color: "#18202f"
    },
    groupButton: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    button: {
      width: 200,
      fontWeight: 700
    },
    buttonRetry: {
      color: "white"
    },
    buttonLeave: {
      backgroundColor: "white"
    }
  })
);

interface BilanProps {
  activity: Activity;
  setShowBilan: (arg: boolean) => void;
  handleCloseLesson: () => void;
}
/**
 * Bilan Component
 */
const Bilan = ({ activity, setShowBilan, handleCloseLesson }: BilanProps) => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();

  /** classes */
  const classes = useStyles();

  /** Retry */
  const retry = async () => {
    const quizStatusTmp = quizStatus;
    delete quizStatusTmp[activity.id];
    window.localStorage.setItem(
      `${window.location.host}-quiz`,
      JSON.stringify(quizStatusTmp)
    );

    await resetAnswers(fetchWithCredentials, activity.id);
    setShowBilan(false);
  };
  /** Leave */
  const leave = () => {
    handleCloseLesson();
  };
  /** Init quizStatus */
  const init = () => {
    const quiz = JSON.parse(
      window.localStorage.getItem(`${window.location.host}-quiz`) || "{}"
    );
    if (quiz) {
      return quiz;
    }
  };
  /** quizStatus */
  const [quizStatus] = useState<any>(init());

  return (
    <div className={classes.correctionView}>
      <Typography
        component="h1"
        variant="h4"
        align="center"
        gutterBottom={true}
        className={classes.title}
      >
        BILAN
      </Typography>
      <Typography
        component="h2"
        align="center"
        gutterBottom={true}
        className={classes.subTitle}
      >
        {activity.validatedScore! >= activity.totalScore! + 2
          ? "Bravo! "
          : null}
        Vous avez eu:
      </Typography>
      <div className={classes.correctionScore}>
        <Grid
          container={true}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <div>
            <span className={classes.ratio}>{activity.validatedScore}</span>/
            {activity.totalScore}
          </div>
          <img src={icon} alt="" className={classes.separatorScore} />
        </Grid>
        <div />
        {activity.validatedScore! < activity.totalScore! ? (
          <p className={classes.correctionComment}>
            C'est bien, mais vous <br />
            pouvez faire encore mieux!
          </p>
        ) : (
          <p className="correction-comment">C'est un sans faute!</p>
        )}
        <Grid className={classes.groupButton}>
          {!activity.started && (
            <Box m={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => retry()}
                className={`${classes.button} ${classes.buttonRetry}`}
              >
                Recommencer
              </Button>
            </Box>
          )}
          <Box m={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => leave()}
              className={`${classes.button} ${classes.buttonLeave}`}
            >
              {activity.started
                ? "Démarrer ma formation"
                : "Quitter l'activité"}
            </Button>
          </Box>
        </Grid>
      </div>
    </div>
  );
};

/** Quiz props */
interface QuizProps {
  quizActivity: Activity;
  handleCloseLesson: () => void;
  showQuizStarterInfo?: boolean;
}

/**
 * Quizz component
 */
const Quiz = ({
  quizActivity,
  handleCloseLesson,
  showQuizStarterInfo
}: QuizProps) => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();
  /** Activity */
  const [activity, setActivity] = useState<Activity>(quizActivity);
  /** Is bilan showing */
  const [isShowBilan, setShowBilan] = useState<any>(
    activity.validatedQuestionCount === activity.questionCount
  );
  /** Loading bilan state */
  const [isLoadingBilan, setLoadingBilan] = useState(false);

  /** Loading question validation */
  const [isLoadingQuestionValidation, setLoadingQuestionValidation] = useState(
    false
  );

  const quizPlayQuestionCorrection = (quizPlayerCallback: any) => {
    sendQuestionId(quizPlayerCallback);
  };

  const classes = useStyles();

  /** Use effect attach event to check when an answer is valided */
  useEffect(() => {
    window.document.addEventListener(
      "QUIZ_PLAYER_QUESTION_CORRECTION",
      quizPlayQuestionCorrection
    );

    return () => {
      window.document.removeEventListener(
        "QUIZ_PLAYER_QUESTION_CORRECTION",
        quizPlayQuestionCorrection
      );
    };
  }, []);

  /** Use effetct loading bilan */
  useEffect(() => {
    if (!isShowBilan) {
      if (activity.validatedQuestionCount === activity.questionCount) {
        setLoadingBilan(true);
        window.location.reload(); // Temporary fix
        return;
      }
      setLoadingBilan(false);
    }
  }, [isShowBilan]);

  /** Use effect loading next btn step */
  useEffect(() => {
    const btnNext = window.document.querySelector(".btn-next");
    btnNext &&
      btnNext.classList.toggle(classes.disable, isLoadingQuestionValidation);
  }, [isLoadingQuestionValidation]);

  /**
   * handle end of a quiz
   */
  const handleEndQuiz = async () => {
    if (!isLoadingBilan) {
      setLoadingBilan(true);
      await getActivity(fetchWithCredentials, activity.id, false).then(
        resultActivity => {
          setActivity(resultActivity.data);
          setShowBilan(true);
        }
      );
    }
  };

  /**
   * sendQuestionId
   * @param quizPlayerDetails
   */
  const sendQuestionId = async (quizPlayerDetails: any) => {
    if (!isLoadingQuestionValidation) {
      setLoadingQuestionValidation(true);
      await postValidedQuestions(
        fetchWithCredentials,
        activity.id,
        +quizPlayerDetails.detail.questionId,
        quizPlayerDetails.detail.userAnswer
      );
      setLoadingQuestionValidation(false);
      activity.started &&
        increaseQuizStarterValidatedQuestionCount(activity.domain.id);
    }
  };

  return (
    <Container maxWidth="md">
      <div className="quiz-content">
        {showQuizStarterInfo && !isShowBilan && (
          <QuizStarterCard domainId={activity.subjectId as DomainId} />
        )}
        {isShowBilan ? (
          activity.started ? (
            <StarterBilan
              handleCloseLesson={handleCloseLesson}
              activity={activity}
            />
          ) : (
            <Bilan
              activity={activity}
              setShowBilan={setShowBilan}
              handleCloseLesson={handleCloseLesson}
            />
          )
        ) : isLoadingBilan ? (
          <ContentLoader
            height={600}
            width={920}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#e3e3e3"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%"></rect>
          </ContentLoader>
        ) : (
          <QuizPlayer
            currentQuestion={(activity.validatedQuestionCount || 0) + 1}
            handleEndQuiz={handleEndQuiz}
            content={activity as any}
            correctionStepByStep={true}
            id={`quiz-${activity.id}`}
            requiredAnswer={true}
            showCorrection={false}
            showFinalScore={false}
          />
        )}
      </div>
      {!isShowBilan && (
        <Paper
          elevation={3}
          style={{
            position:
              navigator.platform === "iPad" || "iPhone" ? "fixed" : "absolute",
            bottom: 0,
            height: "70px",
            left: 0,
            right: 0
          }}
        />
      )}
    </Container>
  );
};

export default React.memo(Quiz);
