// React
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

// Material
import { Typography, makeStyles, Grid, Container } from "@material-ui/core";

// Images
import googleBadge from "../../assets/google-play-badge.png";
import appleBadge from "../../assets/appstore-badge.svg";

// Providers
import { useScrollDispatch } from "../../shared/Scroll.provider";

// provider
import { CONST } from "../../config/constant";

/**
 * Use styles
 */
const useStyles = makeStyles(() => ({
  containerWiew: {
    margin: "0 auto"
  },
  badgeAndroid: {
    maxWidth: 150
  },
  badgeIos: {
    maxWidth: 125
  }
}));

/**
 * Mention component
 */
const Mention = () => {
  const classes = useStyles();
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();
  /**
   * Manage scroll
   */
  useEffect(() => {
    dispatchScroll({ type: "setScrollPosition", scrollPosition: 0 });
  }, []);

  return (
    <Container>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Mentions légales</title>
      </Helmet>
      <Typography
        component="h1"
        variant="h4"
        align="center"
        gutterBottom={true}
        color={"primary"}
      >
        Mentions légales
      </Typography>
      <Typography component="h2" variant="h6" gutterBottom={true}>
        Editeur
      </Typography>
      <Typography
        component="p"
        variant="body2"
        gutterBottom={true}
        paragraph={true}
      >
        Groupe Revue Fiduciaire
        <br />
        Société par actions simplifiée au capital de 1 017 500 € n°552 072 308 –
        RCS Paris
        <br />
        Siège social : 100, rue La Fayette – 75485 Paris Cedex 10
        <br />
        Tél. : 0 826 80 52 52 (0,15€ TTC/min)
        <br />
        Fax : 01 73 01 78 90
        <br />
        E-mail : src@grouperf.com
        <br />
        Directeur de la publication : Yves de la Villeguérin
        <br />
      </Typography>
      <Typography component="h2" variant="h6" gutterBottom={true}>
        Développements
      </Typography>
      <Typography
        component="p"
        variant="body2"
        gutterBottom={true}
        paragraph={true}
      >
        Kreactive (Lyon)
      </Typography>
      <Typography component="h2" variant="h6" gutterBottom={true}>
        Nos applications
      </Typography>
      <Typography component="p" variant="body2" gutterBottom={true}>
        GRF + Restez informé en permanence de l’actualité juridique
      </Typography>
      <Grid container={true} alignItems={"center"} justify={"flex-start"}>
        <Grid item={true}>
          <a
            href="https://itunes.apple.com/fr/app/grf-+/id714087085?mt=8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={appleBadge}
              alt="download app ios"
              className={classes.badgeIos}
            />
          </a>
        </Grid>
        <Grid item={true}>
          <a
            href="https://play.google.com/store/apps/details?id=com.amudo.GRF&hl=fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={googleBadge}
              alt="download app android"
              className={classes.badgeAndroid}
            />
          </a>
        </Grid>
      </Grid>
      <Typography component="h2" variant="h6" gutterBottom={true}>
        Licence et droits de reproduction
      </Typography>
      <Typography component="p" variant="body2" gutterBottom={true}>
        Droits de reproduction et de diffusion réservés © Groupe Revue
        Fiduciaire {CONST.CURRENT_YEAR}. Usage strictement personnel. Par
        l'accès au site, Le Groupe Revue Fiduciaire consent à l'utilisateur qui
        l'accepte une licence dans les présentes conditions.
      </Typography>
      <Typography component="p" variant="body2" gutterBottom={true}>
        La licence confère à l'utilisateur un droit d'usage privé, non collectif
        et non exclusif, sur le contenu du site. Elle comprend le droit de
        reproduire pour stockage aux fins de représentation sur écran monoposte
        et de reproduction,en un exemplaire, pour copie de sauvegarde ou tirage
        sur papier. Toute mise en réseau, toute rediffusion, sous quelque forme,
        même partielle, est donc interdite.
      </Typography>
      <Typography component="p" variant="body2" gutterBottom={true}>
        Ce droit est personnel, il est réservé à l'usage exclusif et non
        collectif du licencié. Il n'est transmissible en aucune manière. Tout
        autre usage est soumis à autorisation préalable et expresse. La
        violation de ces dispositions impératives soumet le contrevenant, et
        toutes personnes responsables, aux peines pénales et civiles prévues par
        la loi.
      </Typography>
      <Typography component="p" variant="body2" gutterBottom={true}>
        Collecte et utilisation des données à caractère personnel Inscription à
        la CNIL et protection des données personnelles. Le Groupe Revue
        Fiduciaire considère que vos coordonnées sont des données
        confidentielles, et s'engage à ne pas les divulguer. Vous disposez d'un
        droit d'accès, de modification, de rectification et de suppression des
        données qui vous concernent (art. 34 de la loi Informatique et libertés
        n° 78-17 du 6 janvier 1978). Vous pouvez exercer ce droit en écrivant à
        : rgpd@grouperf.com
      </Typography>
      <Typography component="p" variant="body2" gutterBottom={true}>
        Les sites Internet Groupe Revue Fiduciaire sont déclarés à la CNIL sous
        le numéro : 753216 La gestion des abonnés actuels et potentiels est
        déclarée à la CNIL sous le numéro : 545 007
      </Typography>
      <Typography component="p" variant="body2" gutterBottom={true}>
        Pour les informations recueillies par formulaire, vous serez susceptible
        de recevoir des informations ou des propositions de nos partenaires.
        Vous disposez d'un droit d'accès à vos données personnelles, de
        rectification et d’opposition. Vous pouvez exercer ce droit en écrivant
        à rgpd@grouperf.com
      </Typography>
    </Container>
  );
};

export default Mention;
