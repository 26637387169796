import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";

// Material:
import { Box, Grid, Theme, createStyles, makeStyles } from "@material-ui/core";

// Store
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../store/reducers";
import {
  fetchHomeActivityList,
  fetchHomeFolder,
  Action
} from "../../store/actions";
import { setProcessingTime } from "../../store/actions/modules/time";
import { ThunkDispatch } from "redux-thunk";

// Components
import ActivityList from "../../features/ActivityList/ActivityList";
import CardFolder from "../../features/CardFolder/CardFolder";
import CardActivity from "../../features/CardActivity/CardActivity";

// Mock
import { provideFullMock } from "../../testing/model.mock";

// Providers
import { useActivityState } from "../../shared/Activity.provider";
import { useScrollDispatch } from "../../shared/Scroll.provider";

// Helpers
import { relocateScroll } from "../../helpers/page.helper";

// Model
import { Activity } from "../../models/Activity.model";
import { Folder } from "../../models/folder.model";
import { User } from "../../models/user.model";

// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";

// Assets
import bandeau from "../../assets/fin-2024.png";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "10%",
        paddingRight: "10%"
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: 40,
        paddingRight: 40
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0
      },
      ["@supports (-webkit-touch-callout: none)"]: {
        marginBottom: 149
      }
    },
    bandeau: {
      width: "100%",
      marginBottom: 12
    }
  })
);

/**
 * Home component
 */
const Home = ({ history }: RouteComponentProps) => {
  /** Classes  */
  const classes = useStyles();
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();
  /** Activity list state   */
  const activityList: Activity[] = useSelector(
    (state: StoreState) => state.homeActivityList
  );
  /** Folder state   */
  const folders: Folder[] | null = useSelector(
    (state: StoreState) => state.homeFolder
  );
  /** Processing time state */
  const processingTime: boolean = useSelector(
    (state: StoreState) => state.processingTime
  );
  /** Processing time state */
  const user: User | null = useSelector((state: StoreState) => state.user);
  /** Loading state   */
  const [loading, setLoading] = useState(true);
  /** Loading Folder state   */
  const [loadingFolder, setLoadingFolder] = useState(true);
  /** Fake Folder   */
  const fakeFolder = provideFullMock().folder;
  /** Fake Activity   */
  const fakeActivity = provideFullMock().lessonVideo;
  /** activitySelected */
  const { activitySelected } = useActivityState();
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();
  /** Dispatch store */
  const dispatch = useDispatch<ThunkDispatch<StoreState, any, Action>>();

  /**
   * Use effect to relocate scroll
   */
  useEffect(() => {
    // Locate scroll
    setTimeout(() => {
      if (relocateScroll(activitySelected)) {
        dispatchScroll({
          type: "setScrollPosition",
          scrollPosition: Number.parseInt(activitySelected.split(";")[1], 10)
        });
      } else {
        dispatchScroll({
          type: "setScrollPosition",
          scrollPosition: 0
        });
      }
    }, 1000);
  }, [activityList]);

  /**
   * Use effect activity list
   */
  useEffect(() => {
    if (activityList.length) {
      setLoading(false);
    }
  }, [activityList.length]);

  /**
   * Use effect folder
   */
  useEffect(() => {
    if (folders) {
      setLoadingFolder(false);
    }
  }, [folders]);

  /**
   * Fetch Home Data
   */
  const fetchHomeData = () => {
    dispatch(fetchHomeActivityList(fetchWithCredentials)).then(() =>
      setLoading(false)
    );

    dispatch(fetchHomeFolder(fetchWithCredentials)).then(() =>
      setLoadingFolder(false)
    );
  };

  /**
   * Use effect get activity list
   */
  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      fetchHomeData();
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, [activitySelected]);

  /**
   * Use effect reload home page date if time is processed
   */
  useEffect(() => {
    if (processingTime) {
      fetchHomeData();
      dispatch(setProcessingTime(false));
    }
  }, [processingTime]);

  const renderFolders = (folders: Folder[]) => {
    return folders.map(folder => {
      return (
        <Box marginTop={1}>
          <CardFolder folder={folder} />
        </Box>
      );
    });
  };

  useEffect(() => {
    if (
      user?.roles.includes("blended") &&
      !user?.availableFullDomainIds.length &&
      user?.availableDomainIds.length
    ) {
      const firstDomain = user?.availableDomainIds[0];
      history.push("categorie/" + firstDomain + "/competences");
    }
  }, [user]);

  return (
    <div>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>À la une</title>
      </Helmet>
      <Grid
        container={true}
        direction="column"
        spacing={2}
        className={classes.container}
      >
        <img
          src={bandeau}
          alt="Nous vous souhaitons bonne fete"
          className={classes.bandeau}
        />
      </Grid>
      {loadingFolder ? (
        <Box marginBottom={15}>
          <CardFolder fake={true} folder={fakeFolder} />
        </Box>
      ) : folders ? (
        renderFolders(folders)
      ) : null}
      {loading ? (
        <Box marginTop={3}>
          <CardActivity fake={true} activity={fakeActivity} />
        </Box>
      ) : activityList && activityList.length > 0 ? (
        <Box marginBottom={15}>
          <ActivityList list={activityList} />
        </Box>
      ) : null}
    </div>
  );
};

export default Home;
