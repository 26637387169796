/**
 * Format date for API RF
 * Use on POST request /timesElapsed
 * @param date
 */
export const formatDateForApi = (date: number) =>
  new Date(date)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

/**
 * Seconds to minutes
 * @param seconds
 * @returns number
 */
export const secondsToMinutes = (seconds: number): number => {
  return Math.floor(seconds / 60);
};

/**
 * Get second formatted with formt xx'j' xx'h' xx'min'
 * @param seconds
 */
export const getSecondsFormatted = (seconds: number) => {
  let delta = seconds;
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;
  const minutes = Math.floor(delta / 60) % 60;

  return (
    (days ? `${days}j` : "") + (hours ? `${hours}h` : "") + `${minutes}min`
  );
};
/**
 * Get seconds formatted only hours
 * @param seconds
 */
export const getSecondsFormattedOnlyHours = (seconds: number): string => {
  const hours = Math.floor(seconds / (60 * 60));

  const divisor_for_minutes = seconds % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;

  return (hours ? `${hours}h` : "") + `${minutes}mn${divisor_for_seconds}s`;
};

/**
 * Formatted date by dd/mm/yyyy
 * @param timestamp
 * @param separator
 * @param format
 */
export const formattedDate = (
  timestamp: number,
  separator?: string,
  format?: string
): string => {
  const separatorDefault = separator ? separator : "/";
  const formatDefault = format ? format : "dd/mm/yyyy";

  const date = new Date(timestamp);
  let month = String(date.getMonth() + 1);
  let day = String(date.getDate());
  const year = String(date.getFullYear());
  month = month.length < 2 ? (month = "0" + month) : month;
  day = day.length < 2 ? (day = "0" + day) : day;

  if (formatDefault === "yyyy/mm/dd") {
    return `${year}${separatorDefault}${month}${separatorDefault}${day}`;
  } else {
    return `${day}${separatorDefault}${month}${separatorDefault}${year}`;
  }
};
