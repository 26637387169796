// React
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";

// Material
import { Paper, makeStyles, createStyles, Fab } from "@material-ui/core";

// Models
import { QuizStarter } from "../../models/Activity.model";
import { DomainId } from "../../models/category.model";

// Assets
import landingQuiz812 from "../../assets/landing-starter-quiz-812.svg";
import landingQuiz930 from "../../assets/landing-starter-quiz-930.svg";
import landingQuiz1073 from "../../assets/landing-starter-quiz-1073.svg";
import landingQuiz1123 from "../../assets/landing-starter-quiz-1123.svg";
import landingQuiz1203 from "../../assets/landing-starter-quiz-1203.svg";
import landingQuiz1374 from "../../assets/landing-starter-quiz-1374.svg";
import landingQuiz1380 from "../../assets/landing-starter-quiz-1380.svg";

// Colors
import { color } from "../../styles/color";

// Config
import { CONST } from "../../config/constant";

const landingQuizSvgs = {
  812: landingQuiz812,
  930: landingQuiz930,
  1073: landingQuiz1073,
  1123: landingQuiz1123,
  1203: landingQuiz1203,
  1374: landingQuiz1374,
  1380: landingQuiz1380
};

/**
 * Styles
 */
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: 40,
      paddingTop: 20,
      marginTop: 30,
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse"
      }
    },
    startBtn: {
      background: "#77C35E",
      color: "#fff",
      fontWeigh: "bold",
      padding: "12px 47px",
      marginTop: 15,
      fontSize: 16
    },
    landingBloc: {
      paddingTop: 20,
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      },
      [theme.breakpoints.up("md")]: {
        width: "50%"
      }
    },
    mainText: {
      fontSize: 24,
      marginTop: 0
    },
    secondaryText: {
      fontSize: 14,
      fontStyle: "italic"
    },
    svg: {
      textAlign: "right",
      mawWidth: "100% !important",
      [theme.breakpoints.down("md")]: {
        textAlign: "center"
      }
    }
  })
);

/** Quiz props */
interface IQuizStarterCardProps {
  quizStarter?: QuizStarter;
  domainId: DomainId;
}

/**
 * Quizz component
 */
const QuizStarterCard = ({
  quizStarter,
  domainId,
  history
}: RouteComponentProps & IQuizStarterCardProps) => {
  const classes = useStyles();
  const landingQuizSvg = landingQuizSvgs[domainId];

  return (
    <Paper className={classes.root}>
      <div className={classes.landingBloc}>
        <p className={classes.mainText}>
          Pour accéder à vos activités, commencez par évaluer vos connaissances.
        </p>
        {quizStarter ? (
          <>
            <p className={classes.secondaryText}>Quelques minutes seulement</p>
            <Fab
              variant="extended"
              size="large"
              className={`${classes.startBtn}`}
              onClick={() => {
                history.push({
                  pathname: "/activite/" + quizStarter.id
                });
              }}
            >
              {quizStarter.validatedQuestionCount
                ? "Terminer mon test"
                : "Démarrer"}
            </Fab>
          </>
        ) : (
          <p className={classes.secondaryText}>
            Réalisez ce quiz d'évaluation pour accéder à tout le contenu de
            formation de la thématique&nbsp;
            <span
              style={{
                color: `${color[domainId]}`
              }}
            >
              {CONST.DOMAIN_LIST[domainId]}
            </span>
          </p>
        )}
      </div>
      <div className={`${classes.landingBloc} ${classes.svg}`}>
        <img src={landingQuizSvg} />
      </div>
    </Paper>
  );
};

export default withRouter(React.memo(QuizStarterCard));
