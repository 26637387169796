import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { reducers } from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const middleware =
  process.env.NODE_ENV !== "production"
    ? [require("redux-immutable-state-invariant").default(), thunk]
    : [thunk];
const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middleware))
);
