// React
import React, { useEffect, useState, memo } from "react";
import { withRouter } from "react-router-dom";

import ContentLoader from "react-content-loader";
import { Helmet } from "react-helmet";

// Material
import { createStyles, makeStyles, Theme } from "@material-ui/core";

// Components
import BaseSkillsItem from "../../features/BaseSkillsItem/BaseSkillsItem";
import ActivityTime from "../../features/ActivityTime/ActivityTime";
import StarterQuizCard from "../Quiz/QuizStarterCard";

// Models
import { Category } from "../../models/category.model";
import { QuizStarter } from "../../models/Activity.model";

// Providers
import { useSubjectState } from "../Subject/Subject.provider";

// Services
import {
  getCategoryList,
  getBreadCrumb
} from "../../services/navigation.service";
import { getQuizStarter } from "../../services/quiz.service";

// Providers
import { useScrollDispatch } from "../../shared/Scroll.provider";

// Helpers
import {
  getGlobalActivityTime,
  getConfirmedActivityTime
} from "../../helpers/time.helper";

// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";
import Box from "@material-ui/core/Box";
import { setErrorToHandleError } from "../../store/errorHandler/actions";
import { useDispatch } from "react-redux";
import LockedBanner from "../../features/LockedBanner/LockedBanner";

/**
 * Get category root
 * @param children
 * @param id
 */
const getCategoryRoot = (children: Category[], id: number): any => {
  let found: Category | undefined;
  if (children) {
    for (const item of children) {
      if (item.id === id) {
        return item;
      } else if (item.children) {
        found = getCategoryRoot(item.children, id);
        if (found) {
          return item;
        }
      }
    }
  }
};

/**
 * Get category to open
 * @param categoryList
 * @param idSubjectSelected
 */
const getCategoryToOpen = (
  categoryList: Category[],
  idSubjectSelected: number
): any => {
  const categoryRootSelected: Category = getCategoryRoot(
    categoryList,
    idSubjectSelected
  );

  return categoryRootSelected ? categoryRootSelected : -1;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up("md")]: {
        paddingRight: 100,
        paddingLeft: 100
      }
    }
  })
);
/**
 * BaseSkills component
 */
const BaseSkills = withRouter(({ match }) => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();
  /** Classes */
  const classes = useStyles();
  /** Category list state */
  const [categoryList, setCategoryList] = useState<Array<Category> | undefined>(
    []
  );
  /** Use subject */
  const { subjectSelected } = useSubjectState();
  /** loading state */
  const [loading, setLoading] = useState(true);
  /** Category list opened */
  const [categoryListOpened] = useState<Category[]>([]);
  /** Domain name */
  const [domainName, setDomainName] = useState<string>("");
  /** Domain id */
  const [domainId, setDomainId] = useState<number>(0);
  /** Loading domain */
  const [loadingDomain, setLoadingDomain] = useState(true);
  /** Quiz Starter State */
  const [quizStarter, setQuizStarter] = useState<QuizStarter | undefined>();
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();
  /** dispatch */
  const dispatch = useDispatch();
  /** show locked notification state */
  const [showLockedNotification, setShowLockedNotification] = useState(false);

  /**
   * BaseSkillLoader
   */
  const BaseSkillLoader = () => (
    <ContentLoader
      height={160}
      width={700}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#e3e3e3"
    >
      <rect x="20" y="10" rx="5" ry="5" width="650" height="25" />
      <rect x="20" y="40" rx="5" ry="5" width="650" height="25" />
      <rect x="20" y="70" rx="5" ry="5" width="650" height="25" />
    </ContentLoader>
  );

  /**
   * Use effect category
   */
  useEffect(() => {
    setQuizStarter(getQuizStarter(match.params.id));
    const { params } = match;
    setLoading(true);
    const fetchData = async () => {
      const result = await getCategoryList(
        fetchWithCredentials,
        params.id
      ).catch(err => {
        dispatch(setErrorToHandleError(true, err.status));
      });
      setCategoryList(result!.data);
      setLoading(false);
    };

    const fetchBreadCrumb = async () => {
      setLoadingDomain(true);
      const result = await getBreadCrumb(fetchWithCredentials, params.id).catch(
        err => {
          dispatch(setErrorToHandleError(true, err.status));
        }
      );
      setDomainName(result.data.domain);
      setDomainId(+params.id);
      setLoadingDomain(false);
    };
    let isSubscribed = true;
    if (isSubscribed) {
      fetchData();
      fetchBreadCrumb();
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, [match.params.id]);

  const activateLockedNotification = () => {
    setShowLockedNotification(true);
    setTimeout(() => {
      setShowLockedNotification(false);
    }, 7500);
  };

  /**
   * Use effect scroll page
   */
  useEffect(() => {
    const idSubjectSelected = Number.parseInt(
      subjectSelected.split(";")[0],
      10
    );
    if (!categoryList) {
      throw new Error();
    }

    if (categoryList?.length && idSubjectSelected !== -1) {
      categoryListOpened.push(
        getCategoryToOpen(categoryList, idSubjectSelected)
      );

      while (categoryListOpened[categoryListOpened.length - 1].children) {
        categoryListOpened.push(
          getCategoryToOpen(
            categoryListOpened[categoryListOpened.length - 1].children!,
            idSubjectSelected
          )
        );
      }
    }
  }, [categoryList, subjectSelected]);

  //Hooks
  useEffect(() => {
    if (categoryListOpened[0]) {
      if (subjectSelected.split(";")[2] === match.url.toString()) {
        dispatchScroll({
          type: "setScrollPosition",
          scrollPosition: Number.parseInt(subjectSelected.split(";")[1], 10)
        });
      } else {
        dispatchScroll({
          type: "setScrollPosition",
          scrollPosition: 0
        });
      }
    }
  }, [categoryList, categoryListOpened]);

  return (
    <div id="baseSkills">
      {showLockedNotification && <LockedBanner />}
      {loadingDomain ? (
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          <title>{domainName} - Socle de compétences</title>
        </Helmet>
      ) : null}
      <div className={classes.container}>
        {loading ? (
          <BaseSkillLoader />
        ) : (
          <React.Fragment>
            <Box marginBottom={20}>
              <ActivityTime
                isActivityList={false}
                domainId={domainId}
                confirmedTime={getConfirmedActivityTime(categoryList!)}
                globalTime={getGlobalActivityTime(categoryList!)}
                globalTimeTitleCustom={"Temps global du domaine"}
              />
              {quizStarter && (
                <div style={{ marginBottom: 15 }}>
                  <StarterQuizCard
                    quizStarter={quizStarter}
                    domainId={match.params.id}
                  />
                </div>
              )}
              {categoryList && categoryList.length > 0
                ? categoryList.map((category: Category) => (
                    <div id={`${category.id}`} key={category.id}>
                      <BaseSkillsItem
                        key={category.id}
                        category={category}
                        isLevelCategory={true}
                        url={match.url}
                        categoryListOpened={categoryListOpened}
                        idDomain={match.params.id}
                        locked={!!quizStarter}
                        onLockedClick={activateLockedNotification}
                      />
                    </div>
                  ))
                : null}
            </Box>
          </React.Fragment>
        )}
      </div>
    </div>
  );
});

export default memo(BaseSkills);
