// React
import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
// Libs
import ContentLoader from "react-content-loader";
import Scrollbars from "react-custom-scrollbars";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";
import { useUserAuthentication } from "@dsk-lib/user";
import { isIPad13 } from "react-device-detect";
import clsx from "clsx";
import queryString from "query-string";
// Material
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
// Components
import Lesson from "../Lesson/Lessons";
import Quiz from "../Quiz/Quiz";
import ColorCircularProgress from "../../features/ColorCircularProgress/ColorCircularProgress";
import Timer from "../../features/Timer/Timer";
// Images
import logo from "../../assets/RF_Elearning_logo.png";
import icClose from "../../assets/ic-close.svg";
import GetAppIcon from "@material-ui/icons/GetApp";
// Styles
import { color } from "../../styles/color";
import "./ActivityLessonOrQuiz.scss";
// Models
import { Activity } from "../../models/Activity.model";
// Services
import { getIsSuperAdmin } from "../../services/user.service";
import {
  getActivity,
  getActivityWordFile
} from "../../services/navigation.service";
import { postTimeActivity } from "../../services/time.service";
// Helpers
import { formatDateForApi } from "../../helpers/date-fomatter.helper";
// Store
import { setProcessingTime } from "../../store/actions/modules/time";
import { useDispatch } from "react-redux";
import { setErrorToHandleError } from "../../store/errorHandler/actions";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: "2001!important" as any
    },
    appBar: {
      backgroundColor: "#ffffff",
      [theme.breakpoints.up("md")]: {
        paddingTop: 20,
        paddingBottom: 20
      }
    },
    appBarTextToLong: {
      backgroundColor: "#ffffff",
      paddingBottom: 5,
      paddingTop: 5,
      ["& > div:first-child"]: {
        minHeight: 114
      }
    },
    appBarTextToLongIpad: {
      backgroundColor: "#ffffff",
      paddingBottom: 5,
      paddingTop: 5,
      ["& > div:first-child"]: {
        minHeight: 160
      }
    },
    logo: {
      width: "200px"
    },
    headerTitle: {
      [theme.breakpoints.up("md")]: {
        textAlign: "center"
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0.5)
      }
    },
    chipDomain: {
      color: "white",
      margin: theme.spacing(0.5)
    },
    button: {
      marginBottom: 2,
      marginLeft: theme.spacing(0.5),
      fontWeight: 600,
      textTransform: "none",
      color: "#000000",
      "&:hover": {
        backgroundColor: "#7ebc4e",
        color: "#FFFFFF"
      }
    },
    activityContent: {
      backgroundColor: "#f1f4f5",
      padding: 16
    },
    btgroup: {
      height: 40,
      marginRight: 10
    },
    textUp: {
      fontSize: 24,
      lineHeight: "normal",
      fontWeight: 600,
      color: "#9ea2aa",
      right: 0,
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
        borderBottom: "none",
        borderTop: "none"
      },
      [theme.breakpoints.up("md")]: {
        borderLeftWidth: 2,
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
        backgroundColor: "white"
      }
    },
    textDown: {
      fontSize: 16,
      fontWeight: 600,
      color: "#18202f",
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
        borderBottom: "none",
        borderTop: "none"
      },
      [theme.breakpoints.up("md")]: {
        borderRightWidth: 2,
        borderTopLeftRadius: 25,
        borderBottomLeftRadius: 25,
        backgroundColor: "white"
      }
    },
    chip: {
      borderRadius: 20,
      width: 90,
      backgroundColor: "white"
    },
    secondToolbar: {
      backgroundColor: "#f9fdfc"
    },
    headerSecondToolbar: {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    headerTitleResponsive: {
      whiteSpace: "nowrap"
    },
    video: {
      textAlign: "center"
    },
    subtitle: {
      fontSize: "inherit"
    }
  })
);

/**
 * Activity component
 */
const ActivityLessonOrQuiz: React.FC<any> = (props: any) => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();

  const { match, history } = props;

  /** Classes */
  const classes = useStyles();
  /** Activity */
  const [activity, setActivity] = useState<Activity>();
  /** FontSize */
  const [fontSize, setFontSize] = useState(16);
  /** URL to redirect when activity is closed */
  const returnURL = match.url.substring(0, match.url.indexOf("/activite/"));
  /** Loading state */
  const [loading, setLoading] = useState(true);
  /** Timer play state */
  const [playTimer, setPlayTimer] = useState(false);
  /** Timer over state */
  const [overTimer, setOverTimer] = useState(false);
  /** Handle Play Timer */
  const handlePlayTimer = (play: boolean, over?: false): void => {
    setPlayTimer(play);
    setOverTimer(!!over);
  };

  /** isSuperAdmin */
  const [isSuperAdmin, setIsSuperAdmin] = React.useState<boolean>(false);
  /** loadingWordFile */
  const [isLoadingWordFile, setIsLoadingWordFile] = React.useState(false);
  /** errorWordFile */
  const [isErrorWordFile, setIsErrorWordFile] = React.useState(false);
  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();
  /** Dispatch store */
  const dispatch = useDispatch();
  /** params */
  const { params } = match;

  const isSendingTime = useRef(false);

  /**
   * This function returns true or false if the text is longer than 70 characters.
   * @returns {"" | undefined | boolean}
   */
  const isLong = () => {
    return activity?.name && activity.name.length > 64;
  };
  const ifIsLong = isLong();

  /**
   * Is lesson
   * @param uniqueId
   */
  const isLesson = (uniqueId: string): boolean => {
    return uniqueId.substring(0, uniqueId.indexOf("_")) === "lesson"
      ? true
      : false;
  };

  /**
   * Handle close lesson
   */
  const handleCloseLesson = (): void => {
    history.goBack();
  };

  /**
   * Increment size text
   */
  const incrementSizeText = (): void => {
    setFontSize(fontSize + 1);
  };

  /**
   * Decrement size text
   */
  const decrementSizeText = (): void => {
    setFontSize(fontSize - 1);
  };

  /** Activity unique id */
  const activityUniqueId = params.id;

  /**
   * Send activity time
   */
  const sendActivityTime = async (start: number, end: number) => {
    if (
      !isSendingTime.current &&
      start &&
      isLesson(params.id) &&
      end - start >= 1000
    ) {
      isSendingTime.current = true;
      postTimeActivity(
        fetchWithCredentials,
        formatDateForApi(start),
        formatDateForApi(end),
        activityUniqueId
      ).then(data => {
        if (data.data.diff > 0) {
          isSendingTime.current = false;
          dispatch(setProcessingTime(true));
        }
      });
    }
  };

  /**
   * Is quiz
   * @param uniqueId
   */
  const isQuiz = (uniqueId: string): boolean => {
    return uniqueId.substring(0, uniqueId.indexOf("_")) === "quiz"
      ? true
      : false;
  };

  /**
   * Get activity as word file
   */
  const fetchWordFile = async () => {
    if (activity) {
      setIsLoadingWordFile(true);
      if (isQuiz(activity.uniqueId)) {
        if (
          await getActivityWordFile(fetchWithCredentials, "quizzes", activity)
        ) {
          setIsLoadingWordFile(false);
        } else {
          setIsLoadingWordFile(false);
          setIsErrorWordFile(true);
        }
      } else {
        if (
          await getActivityWordFile(fetchWithCredentials, "lessons", activity)
        ) {
          setIsLoadingWordFile(false);
        } else {
          setIsLoadingWordFile(false);
          setIsErrorWordFile(true);
        }
      }
    }
  };

  const generateTimer = (display: "chip" | "box", key: number) => {
    return (
      <Timer
        over={overTimer}
        sendTime={sendActivityTime}
        play={playTimer}
        key={key}
        display={display}
        estimated={(activity && activity.time.estimated) || 0}
        elapsed={(activity && activity.time.elapsed) || 0}
      />
    );
  };

  const checkIfShowQuizStarterInfo = (): boolean => {
    return !!queryString.parse(props.location.search).staterInfo;
  };

  /**
   * Use effect error download file
   */
  useEffect(() => {
    if (isErrorWordFile) {
      enqueueSnackbar(
        "Il y a eu une erreur lors du téléchargement. Veuillez réessayer ultérieurement.",
        {
          variant: "error"
        }
      );
      setTimeout(() => {
        setIsErrorWordFile(false);
      }, 5000);
    }
  }, [isErrorWordFile]);

  /**
   * Use effect get activity list
   */
  useEffect(() => {
    /** params */
    const { params } = match;
    /** idActivity */
    const idActivity = Number(
      params.id.substring(params.id.indexOf("_") + 1, params.id.length)
    );

    /** Fetch data */
    const fetchData = async () => {
      setLoading(true);
      const result: any | undefined = await getActivity(
        fetchWithCredentials,
        idActivity,
        isLesson(params.id)
      ).catch(err => {
        dispatch(setErrorToHandleError(true, err.status));
      });
      setActivity(result?.data);
      setLoading(false);
    };

    setIsSuperAdmin(getIsSuperAdmin());
    fetchData();
  }, [match.params.id]);

  return (
    <Dialog
      fullScreen={true}
      open={true}
      keepMounted={true}
      className={classes.root}
    >
      {loading ? (
        <ContentLoader
          height={780}
          width={1180}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="10" y="5" rx="10" ry="10" width="150" height="72" />
          <rect x="200" y="5" rx="10" ry="10" width="800" height="72" />
          <rect x="1050" y="5" rx="10" ry="10" width="100" height="72" />
          <rect x="200" y="100" rx="10" ry="10" width="800" height="600" />
        </ContentLoader>
      ) : activity ? (
        <React.Fragment>
          <Helmet defer={false}>
            <meta charSet="utf-8" />
            <title>
              {activity.domain.name} - activité : {activity.name}
            </title>
          </Helmet>
          <Hidden smDown={true}>
            <AppBar
              position="static"
              className={clsx({
                [classes.appBar]: !ifIsLong,
                [classes.appBarTextToLong]: ifIsLong,
                [classes.appBarTextToLongIpad]: isIPad13
              })}
            >
              <Toolbar>
                <Grid
                  container={true}
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item={true} xs={true}>
                    <img
                      src={logo}
                      className={classes.logo}
                      alt="rf-elearning"
                    />
                  </Grid>
                  <Grid item={true} xs={6} className={classes.headerTitle}>
                    <Chip
                      size="small"
                      label={activity.domain.name}
                      className={classes.chipDomain}
                      style={{
                        backgroundColor: `${color[activity.domain.id]}`
                      }}
                    />
                    <Typography variant="h5" gutterBottom={true}>
                      {activity.name}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={true}>
                    <Grid
                      container={true}
                      alignItems="flex-start"
                      justify="flex-end"
                      direction="row"
                    >
                      {isSuperAdmin ? (
                        <Button
                          variant="outlined"
                          className={classes.button}
                          onClick={() => fetchWordFile()}
                        >
                          {isLoadingWordFile ? (
                            <ColorCircularProgress />
                          ) : (
                            <GetAppIcon />
                          )}
                          Télécharger le Word
                        </Button>
                      ) : null}
                      {!activity.started && (
                        <Button
                          variant="outlined"
                          className={classes.button}
                          onClick={() => handleCloseLesson()}
                        >
                          Quitter l'activité
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Hidden>

          <Hidden mdUp={true}>
            <AppBar position="static" className={classes.appBar}>
              <Grid container={true} direction="column">
                <Toolbar className={classes.headerSecondToolbar}>
                  <Grid container={true} alignItems="center">
                    <Grid item={true} xs={1}>
                      <NavLink to={returnURL}>
                        <img src={icClose} alt="close" />
                      </NavLink>
                    </Grid>
                    <Grid item={true} xs={11} className={classes.headerTitle}>
                      <Scrollbars style={{ height: 40 }}>
                        <Typography
                          variant="h6"
                          gutterBottom={true}
                          className={classes.headerTitleResponsive}
                        >
                          {activity.name}
                        </Typography>
                      </Scrollbars>
                    </Grid>
                  </Grid>
                </Toolbar>

                <Grid
                  container={true}
                  direction="row"
                  alignItems="baseline"
                  className={classes.secondToolbar}
                >
                  <Grid item={true} xs={9}>
                    {isLesson(activity.uniqueId)
                      ? generateTimer("box", 1)
                      : null}
                  </Grid>
                  {isLesson(activity.uniqueId) &&
                  activity.nature !== "VIDEO" ? (
                    <Grid item={true} xs={3}>
                      <Box m={1}>
                        <Grid
                          container={true}
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                        >
                          <ButtonGroup
                            size="medium"
                            variant="outlined"
                            className={classes.btgroup}
                          >
                            <Button
                              className={classes.textDown}
                              onClick={decrementSizeText}
                            >
                              A
                            </Button>
                            <Button
                              className={classes.textUp}
                              onClick={incrementSizeText}
                            >
                              A
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </AppBar>
          </Hidden>

          <DialogContent className={classes.activityContent}>
            <Hidden smDown={true}>
              {isLesson(activity.uniqueId) && activity.nature !== "VIDEO" ? (
                <Grid
                  container={true}
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item={true} xs={true} />
                  <Grid item={true} xs={8} className={classes.headerTitle}>
                    <Grid
                      container={true}
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <ButtonGroup
                        size="medium"
                        variant="outlined"
                        className={classes.btgroup}
                      >
                        <Button
                          className={classes.textDown}
                          onClick={decrementSizeText}
                        >
                          A
                        </Button>
                        <Button
                          className={classes.textUp}
                          onClick={incrementSizeText}
                        >
                          A
                        </Button>
                      </ButtonGroup>
                      {generateTimer("chip", 2)}
                    </Grid>
                  </Grid>
                  <Grid item={true} xs={true} />
                </Grid>
              ) : (
                <Grid container={true}>
                  <Grid item={true} xs={2} />
                  <Grid item={true} xs={8} className={classes.headerTitle}>
                    {isLesson(activity.uniqueId)
                      ? generateTimer("chip", 3)
                      : null}
                  </Grid>
                  <Grid item={true} xs={2} />
                </Grid>
              )}
            </Hidden>

            {isLesson(activity.uniqueId) ? (
              <Lesson
                handlePlayTimer={handlePlayTimer}
                activity={activity}
                fontSize={fontSize}
              />
            ) : (
              <Quiz
                quizActivity={activity}
                handleCloseLesson={handleCloseLesson}
                showQuizStarterInfo={checkIfShowQuizStarterInfo()}
              />
            )}
          </DialogContent>
        </React.Fragment>
      ) : null}
    </Dialog>
  );
};

export default withRouter(ActivityLessonOrQuiz);
