import { Dispatch } from "redux";
import { ActionTypes } from "../actionTypes";

// Model
import { Activity } from "../../../models/Activity.model";
import { Folder } from "../../../models/folder.model";

// Services
import {
  getActivityList,
  getFolder
} from "../../../services/navigation.service";

// Helper
import { getDateReducedMonths } from "../../../helpers/date.helper";
import { formattedDate } from "../../../helpers/date-fomatter.helper";
import { setErrorToHandleError } from "../../errorHandler/actions";

export interface FetchNewsActivityList {
  type: ActionTypes.fetchNewsActivityList;
  payload: Activity[];
  idCategory: number;
}

export interface FetchNewsFolder {
  type: ActionTypes.fetchNewsFolder;
  payload: Folder | null;
  idCategory: number;
}

export const fetchNewsActivityList = (
  fetchCredentials: typeof fetch,
  id: number
) => {
  return async (dispatch: Dispatch) => {
    const result = await getActivityList(
      fetchCredentials,
      id,
      false,
      formattedDate(getDateReducedMonths().getTime(), "-", "yyyy/mm/dd")
    ).catch(err => dispatch(setErrorToHandleError(true, err.status)));

    const data = result.data;

    dispatch<FetchNewsActivityList>({
      type: ActionTypes.fetchNewsActivityList,
      payload: data && data.length > 0 ? data : null,
      idCategory: id
    });
  };
};

export const fetchNewsFolder = (fetchCredentials: typeof fetch, id: number) => {
  return async (dispatch: Dispatch) => {
    const result = await getFolder(fetchCredentials, id).catch(err =>
      dispatch(setErrorToHandleError(true, err.status))
    );

    dispatch<FetchNewsFolder>({
      type: ActionTypes.fetchNewsFolder,
      payload: result ? result.data : null,
      idCategory: id
    });
  };
};
