// Constante
import { CONST } from "../config/constant";

/**
 * getDateReducedMonths
 */
export const getDateReducedMonths = () => {
  const dateReducedMonths = new Date();
  dateReducedMonths.setMonth(
    dateReducedMonths.getMonth() - CONST.MONTH_REDUCED
  );

  return dateReducedMonths;
};
