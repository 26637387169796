import { CONST } from "../config/constant";
import { QuizStarterList, QuizStarter } from "../models/Activity.model";
import { DomainId } from "../models/category.model";

const STARTER_QUIZ_LIST_KEY = "quiz-starter-list";

/**
 * Get user quiz starter list
 */
export const getQuizStarterList = async (
  fetchCredentials: typeof fetch
): Promise<QuizStarterList> => {
  let URL_QUIZ_STARTER_LIST: string = `${process.env.REACT_APP_API_URL_EL}${CONST.API.QUIZ_STARTER_LIST}`;
  const result = await fetchCredentials(URL_QUIZ_STARTER_LIST);

  return (await result.json()).data;
};

export const storeQuizStarterList = (quizStarterList: QuizStarterList) => {
  localStorage.setItem(STARTER_QUIZ_LIST_KEY, JSON.stringify(quizStarterList));
};

export const getQuizStarterListFromLocalStorage = (): QuizStarterList => {
  return JSON.parse(localStorage.getItem(STARTER_QUIZ_LIST_KEY) || "{}");
};

export const getQuizStarter = (domaineId: DomainId): QuizStarter => {
  return JSON.parse(localStorage.getItem(STARTER_QUIZ_LIST_KEY) || "{}")[
    domaineId
  ];
};

export const removeQuizStarter = (domaineId: DomainId) => {
  const quizStarterList = getQuizStarterListFromLocalStorage();
  delete quizStarterList[domaineId];
  storeQuizStarterList(quizStarterList);
};

export const increaseQuizStarterValidatedQuestionCount = (
  domainId: DomainId
): void => {
  const quizStarterList = getQuizStarterListFromLocalStorage();
  quizStarterList[domainId].validatedQuestionCount++;
  storeQuizStarterList(quizStarterList);
};
