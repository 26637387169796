// React
import React, { useState, useEffect, useRef } from "react";

// Redux
import { useDispatch } from "react-redux";
import { setStartDate } from "../../store/actions/modules/time";
import { store } from "../../store/createStore";

// Styles
import { makeStyles, createStyles } from "@material-ui/core/styles";

// Libs
import moment from "moment";

// Material
import { Chip, Typography, Box } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      borderRadius: 20,
      width: 100,
      backgroundColor: "white"
    },
    btgroup: {
      height: 40,
      marginRight: 10
    },
    txtFinished: {
      color: "#7fbc4d"
    },
    icFinished: {
      color: "#7fbc4d"
    },
    icNotFinished: {
      color: "#eaeced",
      "&:hover": {
        color: "#eaeced"
      }
    }
  })
);

/** Timer props */
interface TimerProps {
  sendTime: (start: number, end: number) => void;
  over: boolean;
  play: boolean;
  estimated: number;
  elapsed: number;
  display: "chip" | "box";
}

/**
 * Timer component
 */
const Timer = ({
  sendTime,
  over,
  play,
  estimated,
  elapsed,
  display
}: TimerProps) => {
  const classes = useStyles();

  /** Timer interval */
  const timerInterval = useRef<null | NodeJS.Timeout>(null);

  /** Finished */
  const [finished, setFinished] = useState<boolean>(elapsed >= estimated);

  /** Counter */
  const [counter, setCounter] = useState<number>(
    finished ? estimated : elapsed
  );

  /** Time */
  const time = moment.utc(counter * 1000).format("HH:mm:ss");

  /** Sending time interval in seconds */
  const sendingTimeInterval = 60;

  /** Use dispatch */
  const dispatch = useDispatch();

  const getRemainTime = (): number => {
    return estimated - counter;
  };

  const start = () => {
    if (!finished) {
      dispatch(setStartDate(Date.now()));
      timerInterval.current = setInterval(() => {
        setCounter(count => count + 1);
      }, 1000);
    }
  };

  const cleanup = () => {
    sendTime(store.getState().startDate, Date.now());
    dispatch(setStartDate(0));
    stop();
  };

  const stop = () => {
    timerInterval && clearInterval(timerInterval?.current!);
  };

  /**
   * Use effect send time by regular interval
   */
  useEffect(() => {
    const startDate = store.getState().startDate;
    if (startDate && counter >= sendingTimeInterval) {
      const now = Date.now();
      sendTime(startDate, now);
      dispatch(setStartDate(now));
    }
  }, [Math.ceil(counter / sendingTimeInterval)]);

  /**
   * Use effect init start date when timer is started or finished
   */
  useEffect(() => {
    play ? start() : cleanup();
  }, [play]);

  /**
   * Use effect to completed max time
   */
  useEffect(() => {
    if (over) {
      const startDate = store.getState().startDate;
      const now = Date.now();
      const remainTime = getRemainTime();
      setCounter(estimated);
      sendTime(startDate || now, now + remainTime * 1000);
      stop();
    }
  }, [over]);

  /**
   * Use effect when leaving page where timer is displayed
   */
  useEffect(() => {
    return cleanup;
  }, []);

  /**
   * Use effect checkek if timer is finished
   */
  useEffect(() => {
    if (counter >= estimated) {
      setFinished(true);
      !over && cleanup();
    }
  }, [counter]);

  const checkIcon = (
    <CheckCircleIcon
      className={finished ? classes.icFinished : classes.icNotFinished}
    />
  );

  return display === "chip" ? (
    <Chip
      label={time}
      className={`${classes.btgroup} ${classes.chip} ${
        finished ? classes.txtFinished : ""
      }`}
      variant="outlined"
      onDelete={() => {
        return;
      }}
      deleteIcon={checkIcon}
    />
  ) : (
    <Box m={2} style={{ display: "flex" }}>
      <Typography
        variant="body1"
        gutterBottom={true}
        className={`${finished ? classes.txtFinished : ""}`}
      >
        {time}
      </Typography>
      {checkIcon}
    </Box>
  );
};

export default React.memo(Timer);
