// React
import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps, withRouter } from "react-router";

// Material
import {
  Grid,
  Box,
  Zoom,
  List,
  ListItem,
  MenuItem,
  Menu,
  Container,
  Typography
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

// Styles
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

// Components
import CardDashboard from "../../features/CardDashboard/CardDashboard";
import NoResult from "../../features/NoResult/NoResult";
import CertificatePicker from "../../features/CertificatePicker/CertificatePicker";

// Models
import { DashboardUser } from "../../models/dashboard.model";
import { User } from "../../models/user.model";

// Services
import { getDashboard } from "../../services/navigation.service";
import { getPreferredUsername } from "../../services/user.service";

// Providers
import { useScrollDispatch } from "../../shared/Scroll.provider";

// Store
import { useSelector } from "react-redux";
import { StoreState } from "../../store/reducers";

// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";
import {
  DashboardFormatedTime,
  DashboardResponseFetchApi
} from "../../models/time.model";
import SurveyModal from "../../features/SurveyModal/SurveyModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridAutoRows: "1fr",
      gridColumnGap: 15,
      gridRowGap: 15,
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 100,
        gridTemplateColumns: "repeat(1, 1fr)"
      }
    },
    control: {
      padding: theme.spacing(2)
    },
    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: "black"
      }
    },
    cssFocused: {},
    notchedOutline: {},
    certificationLabel: {
      [theme.breakpoints.up("sm")]: {
        flex: "grow",
        flexGrow: 1
      }
    },
    title: {
      marginLeft: -2,
      color: "#18202f",
      fontWeight: "bold",
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        marginLeft: 0
      }
    },
    totalLabel: {
      fontWeight: 600,
      color: "#263c46",
      fontSize: 13,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign: "center"
      }
    },
    certificationSelectBloc: {
      marginLeft: -2,
      alignItems: "center",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        marginRight: -7,
        width: "100%"
      }
    },
    certificationSelectList: {
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    certificationSelectButton: {
      textTransform: "none",
      border: "1px solid #c3c6cc",
      backgroundColor: "white",
      borderRadius: 5,
      fontSize: 14
    },
    cardList: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    selectItem: {
      minHeight: 20,
      "&:hover": {
        backgroundColor: "gainsboro"
      }
    },
    selectedItem: {
      backgroundColor: "silver"
    },
    buttonDownload: {
      borderRadius: 5,
      height: 53
    }
  })
);

/**
 * Dashboard component
 */
const Dashboard = (props: RouteComponentProps) => {
  /** Keycloak */
  const { keycloak, fetchWithCredentials } = useUserAuthentication();
  /** Classes */
  const classes = useStyles();
  /** Props */
  const { location } = props;

  /** Loading state */
  const [loading, setLoading] = useState(true);
  /** Dashboard */
  const [dashboardList, setDashboardList] = useState<DashboardUser[]>([]);
  /** Dashboard certificates years list */
  const [certificatesYears, setCertificatesYears] = useState<number[]>([]);
  /** TotalYear */
  const [total, setTotal] = useState<DashboardFormatedTime | null>();
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();
  /** User state */
  const user: User | null = useSelector((state: StoreState) => {
    if (location && location.state && location.state.user) {
      return location.state.user;
    }
    return state.user;
  });

  /** titleCustom */
  const [titleCustom, setTitleCustom] = useState<string>("");
  /** Menu anchor */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /** Picker start, end, max and min date */
  const [pickerDates, setPickerDates] = useState({
    start: new Date(),
    end: new Date(),
    min: new Date(),
    max: new Date()
  });

  /** Certification value */
  const [certification, setCertification] = useState<number>();
  /** Certification list */
  const [certificationList, setCertificationList] = useState<
    Array<{ value: number; label: string }>
  >();

  /** Fake Placeholder Card List */
  const fakePlacehoderCardList: number[] = [0, 1, 2, 3];

  /**
   * Manage scroll
   */
  useEffect(() => {
    dispatchScroll({ type: "setScrollPosition", scrollPosition: 0 });
  }, []);

  /**
   * Retreive list of years
   */
  useEffect(() => {
    /** Fetch data */
    const fetchDataCertificatesYears = async () => {
      if (!user || user.availableYears.length === 0) {
        setCertification(new Date().getFullYear());
        setCertificationList([
          {
            value: new Date().getFullYear(),
            label: " Temps de formation réalisé en " + new Date().getFullYear()
          }
        ]);
        setCertificatesYears([new Date().getFullYear()]);
        setTitleCustom(
          "Cet utilisateur n'a pas encore passé du temps de formation réalisé sur une activité"
        );
      } else {
        setCertification(user.availableYears[0]);
        setCertificationList(
          user.availableYears.map((year: number) => {
            return {
              value: year,
              label: " Temps de formation réalisé en " + year
            };
          })
        );
        setCertificatesYears(user.availableYears);
      }
    };
    fetchDataCertificatesYears();
  }, [location.pathname]);

  /**
   * Get data for dashboard
   */
  useEffect(() => {
    /** Fetch data */
    const fetchData = async () => {
      setLoading(true);
      let usernameDefault = getPreferredUsername(keycloak);
      if (location && location.state && location.state.user) {
        usernameDefault = location.state.user.username;
      }
      const result = (await getDashboard(
        fetchWithCredentials,
        certification!.toString(),
        usernameDefault
      )) as DashboardResponseFetchApi;

      if (
        (result.data?.code && result.data.code === 404) ||
        result.data.code === 401
      ) {
        setTitleCustom("Cet utilisateur n'est pas abonné");
      }
      if (
        result.data.message &&
        result.data.message === "Invalid credentials."
      ) {
        setTitleCustom("Vous n'avez pas le droit d'acceder à cette page");
      }

      setDashboardList(result.data.domains ? result.data.domains : []);
      setTotal(result.data.time ? result.data.time.confirmed : null);
      setLoading(false);
    };
    if (certification !== undefined) {
      fetchData();
    }
  }, [certification, certificatesYears]);

  const pickerDisableDate = (date: MaterialUiPickersDate): boolean => {
    if (!user || !date) {
      return false;
    }

    const currentDate: string = date.format("YYYY-MM-DD");

    const isInPeriod = user.allSubscriptionPeriods.find(
      period => currentDate >= period.start && currentDate <= period.end
    );

    return !isInPeriod;
  };

  /**
   * Handle click to open menu
   */
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handle click on menu item
   */
  // tslint:disable-next-line: variable-name
  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLElement>,
    selectedCertification: number
  ) => {
    setAnchorEl(null);
    setCertification(selectedCertification);
  };

  /**
   * Handle menu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /** wrapperRef */
  const wrapperRef = useRef(null);

  /**
   * Hook that alerts clicks outside of the passed ref
   * @param ref
   */
  const useOutsideAlerter = (refLabel: any) => {
    /**
     * Hide menu if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (refLabel.current && !refLabel.current.contains(event.target)) {
        handleClose();
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  };

  useOutsideAlerter(wrapperRef);

  // Use effect
  useEffect(() => {
    if (user && 0 !== user.allSubscriptionPeriods.length) {
      const now = new Date();
      let min = new Date(user.allSubscriptionPeriods[0].start);
      let max = new Date(user.allSubscriptionPeriods[0].end);

      for (const subscription of user.allSubscriptionPeriods) {
        const start = new Date(subscription.start);
        const end = new Date(subscription.end);
        min = min < start ? min : start;
        max = max > end ? max : end;
      }

      if (max > now) {
        max = now;
      }
      const end = max < now ? max : now;

      setPickerDates({ start: min, end, min, max });
    }
  }, [user]);

  return (
    <Box marginLeft={-2} marginRight={-2}>
      <SurveyModal />
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Mon suivi</title>
      </Helmet>
      <CertificatePicker
        disableDate={pickerDisableDate}
        startDate={pickerDates.start}
        endDate={pickerDates.end}
        minDate={pickerDates.min}
        maxDate={pickerDates.max}
        user={user}
      />
      <Container style={{ margin: "0 auto" }} maxWidth="md">
        {!loading && total ? (
          <>
            <Typography
              component="p"
              className={classes.title}
              gutterBottom={true}
              noWrap={true}
            >
              Mon suivi
            </Typography>
            <Grid
              container={true}
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <span className={classes.certificationSelectBloc}>
                <List
                  component="nav"
                  ref={wrapperRef}
                  className={classes.certificationSelectList}
                >
                  <ListItem
                    button={true}
                    onClick={handleClickListItem}
                    className={classes.certificationSelectButton}
                  >
                    Temps de formation réalisé en {certification}&nbsp;
                    <ArrowDropDownIcon />
                  </ListItem>
                </List>
                <Menu
                  id="lock-menu"
                  anchorEl={anchorEl}
                  keepMounted={true}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {certificationList !== undefined
                    ? certificationList.map(option => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          className={`${classes.selectItem} ${
                            certification === option.value
                              ? classes.selectedItem
                              : null
                          }`}
                          onClick={event =>
                            handleMenuItemClick(event, option.value)
                          }
                        >
                          {option.label}
                        </MenuItem>
                      ))
                    : null}
                </Menu>
              </span>
              <Typography
                component="p"
                className={classes.totalLabel}
                gutterBottom={true}
                noWrap={true}
              >
                Total
                {location.state && location.state.user
                  ? ` pour l'utilisateur ${location.state.user.firstname} ${location.state.user.lastname}`
                  : null}
                {" : "}
                {total.legal.hours > 0 ? total.legal.hours + "h" : null}
                {total.legal.minutes > 0 ? total.legal.minutes + "m" : null}
                {parseInt(total.minutes, 0) === 0 &&
                parseInt(total.days, 0) === 0 &&
                parseInt(total.minutes, 0) === 0
                  ? 0 + "m"
                  : null}
                {total.legal.seconds + "s"}
              </Typography>
            </Grid>
          </>
        ) : null}
        <Box m={2} />
        {!loading ? (
          <Grid container={true} className={classes.container} spacing={2}>
            {dashboardList.length > 0 ? (
              dashboardList.map((dashboard: DashboardUser, index: number) => (
                <Zoom
                  in={true}
                  style={{
                    transitionDelay: `${index * 200}ms` // todo: Faire fonctionner l'animation
                  }}
                  key={index}
                >
                  <CardDashboard
                    titleCard={dashboard.name}
                    confirmedTime={
                      (dashboard.time.confirmed as unknown) as number
                    }
                    estimedTime={dashboard.time.estimated}
                    idDomain={dashboard.id}
                  />
                </Zoom>
              ))
            ) : titleCustom !== "" ? (
              <NoResult titleCustom={titleCustom} subTitleCustom={" "} />
            ) : null}
          </Grid>
        ) : (
          <>
            <Grid
              container={true}
              justify="center"
              alignItems="center"
              className={classes.container}
            >
              {fakePlacehoderCardList.map((index: number) => (
                <CardDashboard
                  key={index}
                  titleCard={""}
                  confirmedTime={0}
                  estimedTime={0}
                  idDomain={0}
                  fake={true}
                />
              ))}
            </Grid>
          </>
        )}
      </Container>
    </Box>
  );
};

export default withRouter(Dashboard);
