/**
 * Constante
 */
export const CONST = {
  CURRENT_YEAR: new Date().getFullYear(),
  DOMAIN: "test-rf-elearning.kreactive.eu", // prod : "rf-elearning.com", preprod : "r7-rfelearning.kreactive.eu", test : "test-rf-elearning.kreactive.eu"
  DOMAIN_LIST: {
    812: "Comptabilité",
    930: "Fiscalité des entreprises",
    1073: "Droit du travail",
    1123: "Gestion de la paye",
    1203: "Droit des affaires",
    1374: "Gestion de patrimoine",
    1380: "Performance du cabinet"
  },
  DOMAINETOREDIRECT: {
    path: "offre-exclusive",
    domaineToRedirect:
      "https://boutique.grouperf.com/produit/rf-e-learning-offre-exclusive"
  },
  KEYCLOAK: {
    URL: process.env.REACT_APP_KEYCLOAK_URL || "",
    REALM: process.env.REACT_APP_KEYCLOAK_REALM || "",
    CLIENTID: process.env.REACT_APP_KEYCLOAK_CLIENTID || ""
  },
  RF_CAC_URL: process.env.REACT_APP_URL_RF_CAC,
  PAGE_PRODUCTLIST: "https://portail.rf-elearning.com/",
  API: {
    ACTIVITIES: "/categories/:categoryId/activities",
    CATEGORIES: "/categories/:categoryId/categories",
    CONTACT: "/contact",
    COVER: "/cover",
    DOMAINS: "/domains",
    FOLDER: {
      BASE: "/folder",
      ID: "/:categoryId"
    },
    LESSONS: "/lessons/:activityId",
    QUIZ: "/quizzes/:activityId",
    QUIZ_VALIDED_QUESTION: "/quizzes/:activityId/questions/:questionId",
    QUIZ_RESET: "/quizzes/:activityId/reset",
    SUBJECT: "/contact/subjects",
    TIME: "/activities/:activityId/timesElapsed",
    BREADCRUMB: "/breadcrumb",
    USER: "/users/me",
    USERS: "/users/network-list",
    DASHBOARD: "/dashboard/:year",
    CERTIFICATE: "/certificate",
    DASHBOARD_YEARS: "/dashboard/years",
    ACTIVITY_WORD_FILE: "/:activityType/:activityId/word",
    QUIZ_STARTER_LIST: "/quizzes-started-domains",
    SURVEY: "/users/survey"
  },
  MONTH_REDUCED: 3,
  GA_ID: process.env.REACT_APP_GA_ID || "UA-110057889-2",
  SURVEY_ID: process.env.REACT_APP_SURVEY_ID || "Wsv3c4ai"
};
