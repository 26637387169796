import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";

// Models
import { Activity } from "../../models/Activity.model";

// Services
import {
  getActivityList,
  getBreadCrumb
} from "../../services/navigation.service";

// Components
import ActivityList from "../../features/ActivityList/ActivityList";
import CardActivity from "../../features/CardActivity/CardActivity";
import ActivityTime from "../../features/ActivityTime/ActivityTime";
import NoResult from "../../features/NoResult/NoResult";

// Mocks
import { provideFullMock } from "../../testing/model.mock";

// Helpers
import { relocateScroll } from "../../helpers/page.helper";
import {
  getGlobalActivityTime,
  getConfirmedActivityTime
} from "../../helpers/time.helper";

// Providers
import { useActivityState } from "../../shared/Activity.provider";
import { useScrollDispatch } from "../../shared/Scroll.provider";

// Libs
import { useUserAuthentication } from "@dsk-lib/user";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../store/reducers";
import { setProcessingTime } from "../../store/actions/modules/time";

/**
 * Subject component
 */
const Subject = withRouter(({ match }) => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();
  /** Activity list State */
  const [activityList, setActivityList] = useState<Activity[]>([]);
  /**  Fake Activity */
  const fakeActivity = provideFullMock().lesson;
  /** Loading */
  const [loading, setLoading] = useState(true);
  /** Domain name */
  const [domainName, setDomainName] = useState<string>("");
  /** Domain id */
  const [domainId, setDomainId] = useState<number>(0);
  /** Loading domain */
  const [loadingDomain, setLoadingDomain] = useState(true);
  /** Use activity selected */
  const { activitySelected } = useActivityState();
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();
  /** Processing time state */
  const processingTime: boolean = useSelector(
    (state: StoreState) => state.processingTime
  );
  /** Dispatch store */
  const dispatch = useDispatch();
  /** Match */
  const { params } = match;

  /**
   * Use effect to relocate scroll
   */
  useEffect(() => {
    // Locate scroll
    setTimeout(() => {
      if (relocateScroll(activitySelected)) {
        dispatchScroll({
          type: "setScrollPosition",
          scrollPosition: Number.parseInt(activitySelected.split(";")[1], 10)
        });
      } else {
        dispatchScroll({
          type: "setScrollPosition",
          scrollPosition: 0
        });
      }
    }, 1000);
  }, [activityList]);

  /** Fetch Activity list */
  const fetchData = async () => {
    const result = await getActivityList(fetchWithCredentials, params.id, true);
    setActivityList(result.data);
    setLoading(false);
  };

  /**
   * Use effect get activity list
   */
  useEffect(() => {
    const fetchBreadCrumb = async () => {
      setLoadingDomain(true);
      const ids = match.url.split("/").filter(item => item);
      if (ids.length > 3) {
        let result = null;
        if (ids[3] === "dossier-actualites") {
          result = await getBreadCrumb(fetchWithCredentials, ids[1], ids[4]);
        } else {
          result = await getBreadCrumb(fetchWithCredentials, ids[1], ids[3]);
        }
        setDomainName(result.data.domain + " - " + result.data.subject);
        setDomainId(+ids[1]);
      } else if (ids.length === 3) {
        let result = null;
        if (ids[0] === "a-la-une") {
          result = await getBreadCrumb(fetchWithCredentials, ids[2]);
        }
        setDomainName("A la Une - " + result.data.domain);
      }
      setLoadingDomain(false);
    };

    let isSubscribed = true;
    if (isSubscribed) {
      setLoading(true);
      fetchData();
      fetchBreadCrumb();
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, [match.params.id]);

  /** Use effect force reload activity subject list if time is processing */
  useEffect(() => {
    if (processingTime) {
      fetchData();
      dispatch(setProcessingTime(false));
    }
  }, [processingTime]);

  return (
    <React.Fragment>
      {loadingDomain ? (
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          <title>{domainName}</title>
        </Helmet>
      ) : null}
      {loading ? (
        <CardActivity fake={true} activity={fakeActivity} />
      ) : activityList && activityList.length ? (
        <React.Fragment>
          <ActivityTime
            domainId={domainId}
            confirmedTime={getConfirmedActivityTime(activityList)}
            globalTime={getGlobalActivityTime(activityList)}
          />
          <ActivityList list={activityList} />
        </React.Fragment>
      ) : (
        <NoResult
          titleCustom={
            "Il n'y a actuellement aucune activité rattachée à ce sujet"
          }
          subTitleCustom={" "}
        />
      )}
    </React.Fragment>
  );
});

export default Subject;
