// React
import React, { useState, useEffect, useRef, memo, ChangeEvent } from "react";
import { Helmet } from "react-helmet";

// Lib
import { useSnackbar } from "notistack";
import { useUserAuthentication } from "@dsk-lib/user";

// Material
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  Grid
} from "@material-ui/core";

// Styles
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

// Models
import { MessageContact } from "../../models/messgeContact.model";
import { SubjectContact } from "../../models/subjectContact.model";

// Services
import {
  postMessageContact,
  getSubjectsContact
} from "../../services/navigation.service";

// Components
import NoResult from "../../features/NoResult/NoResult";

// Providers
import { useScrollDispatch } from "../../shared/Scroll.provider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: "100%"
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(2)
    },
    title: {
      color: theme.palette.primary.main
    },
    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: "black"
      }
    },
    cssFocused: {},
    notchedOutline: {},
    button: {
      width: 170,
      height: 46,
      color: "white"
    },
    buttonarea: {
      display: "flex",
      justifyContent: "center"
    },
    snackbar: {
      top: 84,
      [theme.breakpoints.up("sm")]: {
        transform: "translateX(0%)"
      }
    }
  })
);

/**
 * Contact component
 */
const Contact = () => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();
  /** Styles */
  const classes = useStyles();

  /** subjectList  */
  const [subjectList, setSubjectList] = useState<SubjectContact[]>([]);
  /** subject */
  const [subject, setSubject] = useState<string>("");
  /** email */
  const [email, setEmail] = useState<string>("");
  /** error email */
  const [errorEmail, setErrorEmail] = useState<string>("");
  /** message */
  const [message, setMessage] = useState<string>("");
  /** error message */
  const [errorMessage, setErrorMessage] = useState<string>("");
  /** isShow */
  const [isShow, setShow] = useState<boolean>(false);
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();
  /** emailInput */
  const emailInputRef = useRef<HTMLDivElement>(null);
  /** messageInput */
  const messageInputRef = useRef<HTMLDivElement>(null);
  /** loading state */
  const [loading, setLoading] = useState(true);
  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Detect show change
   */
  useEffect(() => {
    if (isShow) {
      enqueueSnackbar("Votre message a bien été envoyé!", {
        variant: "success"
      });
    }
  }, [isShow]);

  /**
   * Manage scroll
   */
  useEffect(() => {
    dispatchScroll({ type: "setScrollPosition", scrollPosition: 0 });
  }, []);

  /**
   * useEffect fetchSubjects
   */
  useEffect(() => {
    const fetchSubjects = async () => {
      const result = await getSubjectsContact(fetchWithCredentials);
      setSubjectList(result.data);
      setLoading(false);
      if (result.data && result.data.lenght && result.data[0].value) {
        setSubject(result.data[0].value);
      }
    };
    fetchSubjects();
  }, []);

  /**
   * Handle submit
   * @param event
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    setErrorEmail(
      validateEmail(email) ? "" : "Votre adresse email n'est pas valide"
    );
    setErrorMessage(
      message.length > 0 ? "" : "Veuillez compléter votre demande"
    );

    if (isValidForm()) {
      const messageContact: MessageContact = {
        email,
        subject: subjectList!.find(element => element!.value === subject)!.key,
        message
      };
      const result = postMessageContact(fetchWithCredentials, messageContact);
      setEmail("");
      setMessage("");
      setSubject(subjectList[0].value);
      setShow(result ? true : false);
    }
  };

  /**
   * on focus input
   * @param event
   */
  const handleFocus = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (event.target.id === "email" && emailInputRef.current) {
      dispatchScroll({
        type: "setScrollPosition",
        scrollPosition: emailInputRef.current.offsetTop - 20
      });
    } else if (event.target.id === "message" && messageInputRef.current) {
      dispatchScroll({
        type: "setScrollPosition",
        scrollPosition: messageInputRef.current.offsetTop - 20
      });
    }
  };

  /**
   * Validate mail
   * @param email
   */
  const validateEmail = (emailValid: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(emailValid).toLowerCase());
  };
  /**
   * Is valid form
   */
  const isValidForm = (): boolean => message !== "" && validateEmail(email);

  /**
   * on change email
   * @param event
   */
  const onChangeEmail = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setEmail(event.target.value);
    setErrorEmail(
      validateEmail(event.target.value)
        ? ""
        : "Votre adresse email n'est pas valide"
    );
  };

  /**
   * on change message
   * @param event
   */
  const onChangeMessage = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setMessage(event.target.value);
    setErrorMessage(
      event.target.value !== "" ? "" : "Veuillez compléter votre demande"
    );
  };

  return (
    <Container id="contact">
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Formulaire de contact</title>
      </Helmet>
      {!loading ? (
        subjectList && subjectList.length > 0 ? (
          <React.Fragment>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              gutterBottom={true}
              color={"primary"}
              className={classes.title}
            >
              {" "}
              Contact{" "}
            </Typography>
            <Typography variant="subtitle1" align="center" color="textPrimary">
              Nous sommes à votre disposition pour répondre à vos questions et
              vous accompagner. Contactez-nous !
            </Typography>
            <form
              name="frm"
              className={classes.form}
              onSubmit={handleSubmit}
              noValidate={true}
            >
              <Typography> Email* </Typography>
              <TextField
                ref={emailInputRef}
                id="email"
                value={email}
                placeholder="email@domain.com"
                onChange={event => onChangeEmail(event)}
                onFocus={event => handleFocus(event)}
                margin="normal"
                variant="outlined"
                className={classes.input}
                error={errorEmail !== ""}
                helperText={errorEmail}
                required={true}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline
                  }
                }}
              />
              <Typography> Nature de la demande* </Typography>
              <TextField
                id="subject"
                select={true}
                margin="normal"
                variant="outlined"
                className={classes.input}
                value={subject}
                onChange={e => setSubject(e.target.value)}
                required={true}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline
                  }
                }}
              >
                {subjectList.map((option: SubjectContact) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <Typography> Message* </Typography>
              <TextField
                ref={messageInputRef}
                id="message"
                placeholder="Votre message ici"
                value={message}
                onChange={event => onChangeMessage(event)}
                onFocus={event => handleFocus(event)}
                multiline={true}
                rows="8"
                margin="normal"
                variant="outlined"
                className={classes.input}
                required={true}
                error={errorMessage !== ""}
                helperText={errorMessage}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline
                  }
                }}
              />
              <div className={classes.buttonarea}>
                <Grid
                  container={true}
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Button
                    color="primary"
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    className={classes.button}
                  >
                    {" "}
                    Envoyer{" "}
                  </Button>
                  {isShow ? (
                    <Typography color={"primary"} variant="h6">
                      Votre message a bien été envoyé!
                    </Typography>
                  ) : null}
                </Grid>
              </div>
            </form>
          </React.Fragment>
        ) : (
          <NoResult
            titleCustom={"Vous n'avez pas le droit d'acceder à cette page"}
            subTitleCustom={" "}
          />
        )
      ) : null}
    </Container>
  );
};

export default memo(Contact);
