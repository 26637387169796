/** Constante */
import { CONST } from "../config/constant";
/**
 * Post time activity
 * @param startDate
 * @param endDate
 * @param activityId
 */
export const postTimeActivity = async (
  fetchCredentials: typeof fetch,
  startDate: string,
  endDate: string,
  activityId: string
) => {
  /** URL Time */
  const URL_TIME = `${process.env.REACT_APP_API_URL_EL}${CONST.API.TIME}`;
  /** params */
  const params = {
    end: endDate,
    start: startDate
  };

  const result = await fetchCredentials(
    URL_TIME.replace(/:activityId/, activityId.toString()),
    {
      method: "post",
      body: JSON.stringify(params)
    }
  );

  return await result.json();
};
