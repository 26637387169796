// Polyfill
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
// Keycloak
import { UserAuthenticationProvider } from "@dsk-lib/user";
// Core
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory, History } from "history";
// Assets
import "./styles/index.scss";
// Component
import App from "./views/App/App";
// ServiceWorker
import * as serviceWorker from "./serviceWorker";
// Google analytics
import ReactGA from "react-ga";
// Contantes
import { CONST } from "./config/constant";
// Providers
import { ScrollProvider } from "./shared/Scroll.provider";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// Store
import { Provider } from "react-redux";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { store } from "./store/createStore";
// Moment
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/fr";

moment.locale("fr");

/** Google analytics init */
ReactGA.initialize([
  {
    debug: process.env.NODE_ENV === "development",
    gaOptions: {},
    titleCase: false,
    trackingId: CONST.GA_ID
  }
]);

/** Keycloak config */
const keycloakConfig = {
  url: CONST.KEYCLOAK.URL,
  realm: CONST.KEYCLOAK.REALM,
  clientId: CONST.KEYCLOAK.CLIENTID
};

/** Referrer */
!localStorage.getItem("referrer") &&
  !document.referrer.includes(window.location.hostname) &&
  CONST.PAGE_PRODUCTLIST.includes(document.referrer) &&
  localStorage.setItem("referrer", document.referrer);

/** Browser history */
const history: History = createBrowserHistory();

/** History listener for GA */
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const themeRF = createMuiTheme({
  typography: {
    fontFamily: ['"Open Sans"', "sans-serif"].join(",")
  },
  palette: {
    primary: {
      main: "#77C35E"
    }
  },
  overrides: {
    MuiToolbar: {
      regular: {
        height: "56px",
        minHeight: "56px"
      }
    },
    MuiSnackbarContent: {
      root: {
        display: "block"
      },
      action: {
        display: "block",
        margin: "0 !important",
        padding: "0 !important"
      }
    }
  }
});

ReactDOM.render(
  <UserAuthenticationProvider
    keycloakConfig={keycloakConfig}
    authorizationHeaderName="rf-authorization"
  >
    <Router history={history}>
      <Provider store={store}>
        <ScrollProvider>
          <MuiThemeProvider theme={themeRF}>
            <SnackbarProvider>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={MomentUtils}
                locale="fr"
              >
                <App />
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </ScrollProvider>
      </Provider>
    </Router>
  </UserAuthenticationProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
