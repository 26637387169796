import { Action, ActionTypes } from "../../actions";

export const processingTimeReducer = (
  state: boolean = false,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.fetchProcessingTime:
      return action.paypload;
    default:
      return state;
  }
};

export const startDateReducer = (state: number = 0, action: Action) => {
  switch (action.type) {
    case ActionTypes.fetchStartDate:
      return action.paypload;
    default:
      return state;
  }
};
