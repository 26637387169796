// React
import React, { useEffect } from "react";
// Material
import {
  Theme,
  Typography,
  Paper,
  Grid,
  Hidden,
  Fab,
  Button
} from "@material-ui/core";
// Service
import { removeQuizStarter } from "../../../services/quiz.service";
// Icons
import { ArrowForward } from "@material-ui/icons";
// Styles
import { createStyles, makeStyles } from "@material-ui/styles";
import { handleBilanColor } from "../../../helpers/color-translator.helper";
// Components
import QuizAnswerProgress from "./QuizAnswerProgress/QuizAnswerProgress";
import BilanThemeCard from "./BilanThemeCard/BilanThemeCard";
// Models
import { IQuestionReport, Activity } from "../../../models/Activity.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: "white",
      textTransform: "none",
      fontSize: 16,
      [theme.breakpoints.up("md")]: {
        margin: 10
      }
    },
    closeBtn: {
      color: "white",
      backgroundColor: "#77C35E"
    },
    bilanHeaderTitle: {
      fontSize: 18,
      lineHeight: 2.33,
      paddingTop: 8,
      marginTop: -25,
      zIndex: 3,
      marginBottom: 30,
      backgroundColor: "#f1f4f5",
      fontWeight: "bold",
      position: "relative"
    },
    themeLabel: {
      fontWeight: "bold",
      display: "inline-block",
      marginRight: 15,
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },
    pagerContainer: {
      width: 960,
      maxWidth: "100%",
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down("sm")]: {}
    },
    pagerNavigation: {
      [theme.breakpoints.down("sm")]: {
        height: "100%"
      },
      [theme.breakpoints.up("md")]: {
        transform: "translateY(-50%)"
      }
    }
  })
);

/**
 * Bilan props
 */
interface IStarterBilanProps {
  activity: Activity;
  handleCloseLesson: () => void;
}

/**
 * Bilan Component
 */
const StarterBilan = ({ activity, handleCloseLesson }: IStarterBilanProps) => {
  /** classes */
  const classes = useStyles();

  /** Correct answer percentage */
  const correctAnswerPercentage = activity.totalScore
    ? ((100 * (activity.validatedScore || 0)) / activity.totalScore).toFixed(0)
    : 0;

  /** Use effect remove quiz starter */
  useEffect(() => {
    removeQuizStarter(activity.domain.id);
  }, []);

  return (
    <>
      {activity.validatedQuestionCount ? (
        <>
          <QuizAnswerProgress progress={+correctAnswerPercentage} />
          <Typography
            component="p"
            align="center"
            className={classes.bilanHeaderTitle}
            style={{
              color: handleBilanColor(+correctAnswerPercentage)
            }}
          >
            Vous avez eu {correctAnswerPercentage}% de bonnes réponses
          </Typography>

          <div style={{ paddingBottom: 30 }}>
            {activity.report!.map((themeBilan: IQuestionReport, index) => (
              <BilanThemeCard
                index={++index}
                key={index}
                label={themeBilan.label}
                progress={themeBilan.progress}
              />
            ))}
          </div>
          <Paper
            elevation={3}
            style={{
              position:
                navigator.platform === "iPad" || "iPhone"
                  ? "fixed"
                  : "absolute",
              bottom: 0,
              height: "70px",
              left: 0,
              right: 0
            }}
          >
            <div className={classes.pagerContainer}>
              <Grid item={true} xs={true} className={classes.pagerNavigation}>
                <Hidden mdUp={true}>
                  <Grid
                    container={true}
                    justify="center"
                    alignItems="stretch"
                    style={{ height: "100%" }}
                  >
                    <Grid
                      item={true}
                      container={true}
                      xs={6}
                      alignItems="center"
                      justify="center"
                    >
                      <Button
                        color="primary"
                        className={`${classes.button} ${classes.closeBtn}`}
                        onClick={handleCloseLesson}
                      >
                        &nbsp;Démarrer ma formation
                        <ArrowForward />
                      </Button>
                    </Grid>
                  </Grid>
                </Hidden>

                <Hidden smDown={true}>
                  <Grid container={true} justify="center" alignItems="center">
                    <Fab
                      variant="extended"
                      size="large"
                      className={`${classes.button} ${classes.closeBtn}`}
                      onClick={handleCloseLesson}
                    >
                      &nbsp;Démarrer ma formation <ArrowForward />
                    </Fab>
                  </Grid>
                </Hidden>
              </Grid>
            </div>
          </Paper>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default React.memo(StarterBilan);
