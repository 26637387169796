// React
import React, { useState, useEffect } from "react";
import { NavLink, NavLinkProps, withRouter } from "react-router-dom";
import hexToRgba from "hex-to-rgba";
import Scrollbars from "react-custom-scrollbars";
import { RouteComponentProps } from "react-router";

// Material
import {
  Hidden,
  List,
  ListItemText,
  ListItem,
  ListSubheader,
  Collapse,
  Grid,
  Typography,
  Divider
} from "@material-ui/core";

// React loader
import ContentLoader from "react-content-loader";

// Icons
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

// Style
import { makeStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/styles";
import { color } from "../../styles/color";

// Interface
import { Domain } from "../../models/domain.model";
import { User } from "../../models/user.model";

// Images
import logoRF from "../../assets/Logo_RF_Quadri.png";
import iconArtist from "../../assets/icons-icon-artists.svg";
import iconPerson from "../../assets/icons-icon-person.svg";

// Services
import { disconnectUser } from "../../services/user.service";

// Helpers
import { titleCase } from "../../helpers/card.helper";
import { isMultipleDomains } from "../../helpers/domain.helper";

// Store
import { useSelector } from "react-redux";
import { StoreState } from "../../store/reducers";

// keycloak
import { useUserAuthentication } from "@dsk-lib/user";

// Config
import { CONST } from "../../config/constant";

/**
 * Use style
 */
const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  containerClose: {
    height: "100%"
  },
  listContainer: {
    maxWidth: 360
  },
  colorALaUne: {
    backgroundColor: "#CDE3DC"
  },
  textALaUne: {
    color: "#037450"
  },
  textSubheader: {
    fontWeight: 600,
    color: "#1d1d1b"
  },
  itemMenu: {
    paddingTop: 2,
    paddingBottom: 2
  },
  nav: {
    paddingTop: 0
  },
  bullet: {
    width: 20,
    height: 10,
    borderRadius: 5,
    backgroundColor: "grey"
  },
  bulletSmall: {
    width: 10,
    height: 5,
    borderRadius: 5,
    backgroundColor: "grey"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  image: {
    width: 130
  },
  logo: {
    width: 150
  },
  smallTextItem: {
    fontSize: 14
  },
  textDeconnection: {
    color: "#e51049"
  },
  adminLabel: {
    paddingLeft: 15
  }
}));

/**
 * Bullet
 */
const Bullet = ({ domainId = 0, isDomain = false }) => {
  const classes = useStyles();

  return (
    <div
      className={isDomain ? classes.bullet : classes.bulletSmall}
      style={{ backgroundColor: `${color[domainId]}` }}
    />
  );
};

/**
 * Menu Props
 */
interface MenuProps {
  domainList: Domain[];
  loading: boolean;
  username?: string;
  isAdmin: boolean;
  onClose: () => void;
  hasSubscription: boolean;
  isSuperAdmin?: boolean;
}
/**
 * Menu Component
 */
const Menu = (props: RouteComponentProps & MenuProps) => {
  const {
    location: { pathname },
    match,
    domainList,
    loading,
    username,
    isAdmin,
    onClose,
    isSuperAdmin,
    hasSubscription
  } = props;

  /** Keycloak */
  const { keycloak } = useUserAuthentication();

  /** Classes */
  const classes = useStyles();
  /** Wrapped nav link */
  // tslint:disable-next-line:no-shadowed-variable
  const WrappedNavLink = React.forwardRef((props: NavLinkProps, ref: any) => (
    <NavLink {...props} innerRef={ref} />
  ));
  /** State Domain selected */
  const [domainSelected, setDomainSelected] = useState(0);
  /** User state */
  const user: User | null = useSelector((state: StoreState) => state.user);
  /** Actuality selected */
  const actualitySelected = () => {
    return pathname.includes("actualites");
  };
  /** Competence selected */
  const competenceSelected = () => {
    return pathname.includes("competences");
  };
  /** Get background color */
  const getBackgroundColor = (
    domainId = 0,
    conditional = false
  ): CSSProperties => {
    return conditional
      ? {
          backgroundColor: hexToRgba(color[domainId], 0.2)
        }
      : {};
  };
  /**
   * HandleCloseItem
   */
  const handleCloseItem = (item?: number) => {
    if (username) {
      if (item) {
        setDomainSelected(item);
      }
      onClose();
    }
  };

  /** BulletList loader */
  const BulletListLoader = () => (
    <ContentLoader
      height={160}
      width={400}
      speed={1}
      primaryColor="#f3f3f3"
      secondaryColor="#e3e3e3"
    >
      <rect x="22" y="25" rx="7" ry="7" width="27" height="14" />
      <rect x="60" y="17" rx="5" ry="5" width="300" height="30" />
      <rect x="22" y="72" rx="7" ry="7" width="27" height="14" />
      <rect x="60" y="64" rx="5" ry="5" width="300" height="30" />
      <rect x="22" y="117" rx="7" ry="7" width="27" height="14" />
      <rect x="60" y="109" rx="5" ry="5" width="300" height="30" />
    </ContentLoader>
  );

  /**
   * Use effect
   */
  useEffect(() => {
    const pathnames = pathname.split("/").filter((item: string) => item);
    pathnames[0] === "categorie"
      ? setDomainSelected(+pathnames[1])
      : setDomainSelected(0);
  }, [pathname]);

  /**
   * Check if we should show "Actualité" item for a blended role
   */
  const showActualityItem = (domainId: number): boolean => {
    return user && user.roles.includes("blended")
      ? user.availableFullDomainIds.includes(domainId)
      : true;
  };

  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <Scrollbars autoHide={true}>
        <List component="nav" className={classes.nav}>
          {domainList && domainList.length > 0 ? (
            <React.Fragment>
              {user && user.fullDomainAccess ? (
                <ListItem
                  component={WrappedNavLink}
                  to="/a-la-une"
                  button={true}
                  className={classes.colorALaUne}
                  onClick={() => handleCloseItem(0)}
                >
                  <ListItemIcon>
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    inset={false}
                    primary={"À la une"}
                    className={classes.textALaUne}
                  />
                </ListItem>
              ) : null}

              <ListSubheader
                component="div"
                className={classes.textSubheader}
                disableSticky={true}
              >
                {isMultipleDomains(domainList.length)}
              </ListSubheader>
            </React.Fragment>
          ) : null}

          {loading ? (
            <BulletListLoader />
          ) : domainList && domainList.length > 0 ? (
            domainList.map((domain: Domain) => (
              <React.Fragment key={domain.id}>
                <ListItem
                  component={WrappedNavLink}
                  button={true}
                  onClick={() => handleCloseItem(domain.id)}
                  to={`${match.url}categorie/${domain.id}/${
                    showActualityItem(domain.id) ? `actualites` : `competences`
                  }`}
                  style={getBackgroundColor(
                    domain.id,
                    domainSelected === domain.id
                  )}
                  className={classes.itemMenu}
                >
                  <ListItemIcon>
                    <Bullet domainId={domain.id} isDomain={true} />
                  </ListItemIcon>
                  <ListItemText primary={domain.name} />
                </ListItem>
                <Collapse
                  in={domainSelected === domain.id}
                  timeout="auto"
                  unmountOnExit={true}
                >
                  <List component="div" disablePadding={true}>
                    {showActualityItem(domain.id) ? (
                      <ListItem
                        component={WrappedNavLink}
                        button={true}
                        dense={true}
                        className={classes.nested}
                        to={`${match.url}categorie/${domain.id}/actualites`}
                        selected={actualitySelected()}
                        style={getBackgroundColor(
                          domain.id,
                          actualitySelected()
                        )}
                        onClick={() => handleCloseItem()}
                      >
                        <ListItemIcon>
                          <Bullet domainId={domain.id} />
                        </ListItemIcon>
                        <ListItemText primary="Actualités" />
                      </ListItem>
                    ) : null}
                    <ListItem
                      component={WrappedNavLink}
                      button={true}
                      dense={true}
                      className={classes.nested}
                      to={`${match.url}categorie/${domain.id}/competences`}
                      selected={competenceSelected()}
                      style={getBackgroundColor(
                        domain.id,
                        competenceSelected()
                      )}
                      onClick={() => handleCloseItem()}
                    >
                      <ListItemIcon>
                        <Bullet domainId={domain.id} />
                      </ListItemIcon>
                      <ListItemText primary="Socle de compétences" />
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            ))
          ) : null}

          <ListSubheader
            component="div"
            className={classes.textSubheader}
            disableSticky={true}
          >
            MON TABLEAU DE BORD
          </ListSubheader>
          <Hidden smUp={true}>
            {username ? (
              <ListItem
                button={false}
                onClick={() => handleCloseItem(0)}
                className={classes.itemMenu}
              >
                <ListItemText primary={titleCase(username!)} />
              </ListItem>
            ) : null}
            {isSuperAdmin && (
              <Typography className={classes.adminLabel} variant={"caption"}>
                Super Admin
              </Typography>
            )}
          </Hidden>
          {hasSubscription ? (
            <ListItem
              component={WrappedNavLink}
              to={"/mon-suivi"}
              button={true}
              onClick={() => handleCloseItem(0)}
              className={classes.itemMenu}
            >
              <ListItemIcon>
                <img src={iconPerson} alt="icon-artics" />
              </ListItemIcon>
              <ListItemText inset={false} primary={"Mon suivi"} />
            </ListItem>
          ) : null}
          {isAdmin ? (
            <ListItem
              component={WrappedNavLink}
              to={"/admin"}
              button={true}
              onClick={() => handleCloseItem(0)}
              className={classes.itemMenu}
            >
              <ListItemIcon>
                <img src={iconArtist} alt="icon-artics" />
              </ListItemIcon>
              <ListItemText inset={false} primary={"Mes collaborateurs"} />
            </ListItem>
          ) : null}
          <Hidden smUp={true}>
            <Divider component="li" />
            <ListItem button={true} onClick={() => handleCloseItem(0)}>
              <ListItemText
                disableTypography={true}
                inset={true}
                primary={"Déconnexion"}
                onClick={() => disconnectUser(keycloak)}
                className={`${classes.smallTextItem} ${classes.textDeconnection}`}
              />
            </ListItem>
            <Divider component="li" />
          </Hidden>
          <ListSubheader
            component="div"
            className={classes.textSubheader}
            disableSticky={true}
          >
            EN SAVOIR PLUS
          </ListSubheader>
          <ListItem
            component={WrappedNavLink}
            to="/contact"
            button={true}
            onClick={() => handleCloseItem(0)}
            className={classes.itemMenu}
          >
            <ListItemText
              disableTypography={true}
              inset={true}
              primary={"Contactez-nous"}
              className={classes.smallTextItem}
            />
          </ListItem>
          <ListItem
            component={WrappedNavLink}
            to="/mention"
            button={true}
            onClick={() => handleCloseItem(0)}
            className={classes.itemMenu}
          >
            <ListItemText
              disableTypography={true}
              inset={true}
              primary={"Mentions légales"}
              className={classes.smallTextItem}
            />
          </ListItem>
        </List>
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          direction="column"
        >
          <Grid item={true}>
            <img
              src={logoRF}
              alt="revue fiduciaire"
              className={classes.image}
            />
          </Grid>
          <Grid item={true}>
            <Typography variant="body2" align={"center"}>
              Tous droits réservés
            </Typography>
            <Typography variant="body2" align={"center"}>
              Revue Fiduciaire © {CONST.CURRENT_YEAR}
            </Typography>
          </Grid>
        </Grid>
      </Scrollbars>
    </React.Fragment>
  );
};

export default withRouter(Menu);
