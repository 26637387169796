// Redux
import { Dispatch } from "redux";
import { ActionTypes } from "../actionTypes";

export interface FetchProcessingTime {
  type: ActionTypes.fetchProcessingTime;
  paypload: boolean;
}

export interface FetchStartDate {
  type: ActionTypes.fetchStartDate;
  paypload: number;
}

export const setProcessingTime = (processed: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch<FetchProcessingTime>({
      type: ActionTypes.fetchProcessingTime,
      paypload: processed
    });
  };
};

export const setStartDate = (startDate: number) => {
  return async (dispatch: Dispatch) => {
    dispatch<FetchStartDate>({
      type: ActionTypes.fetchStartDate,
      paypload: startDate
    });
  };
};
